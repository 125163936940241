import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import Image from "next/image";

import Heading from "../commons/Heading";
import Btn from "../commons/btn";

const BlockCtaTest = ({ blockInfo }) => {
  const {index, title, content, image, backgroundEffects} = blockInfo;


  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  return (
    <section className={`
      block-cta-test block-index-${index} sm:relative
      ${backgroundEffects?.options?.onEnter?.options?.background == '#FFFFFF' || backgroundEffects?.activate == null && 'block-bck-spa-white'}
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div className="container relative z-20 common-block-padding">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="
            col-span-2 xs:col-span-12 sm:col-span-9 sm:col-start-4 md:col-span-8 md:col-start-5
            lg:col-span-6 lg:col-start-6 2xl:col-span-5 2xl:col-start-6
          ">
            <Heading tag="h2" text={title} classes={"leading-[105%] mb-30 4xl:mb-45 6xl:mb-60"} />
            <h3 className="mb-45 4xl:mb-60 6xl:mb-75 h5">{content}</h3>
            <div className="flex flex-col xs:flex-row items-center gap-normal">
              <Btn type="contact-no-fun" />
              <Btn type="normal-test" />
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(image) && (
        <div className="relative z-10 sm:absolute sm:bottom-0 sm:left-0 md:w-[36%] lg:w-[40%] xl:w-[33%]">
          <Image
            src={image?.sourceUrl}
            width={image?.mediaDetails?.width}
            height={image?.mediaDetails?.height}
            alt={title}
            className="-mt-100 w-full h-auto xs:mt-0 sm:max-w-[75%] md:max-w-none"
          />
        </div>
      )}
    </section>
  )
}

export default BlockCtaTest