import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";

import LeftCardForm from "./LeftCardForm";
import RightCardForm from "./RightCardForm";

const WrapperFormCard = ({ formId, leftSide, showrooms, systems }) => {
  const regex = /\[contact-form-7 id="(\d+)"\s*title=".*"]/;
  const matches = formId.match(regex);
  let id = null;
  if (matches && matches.length > 1) {
    id = parseInt(matches[1]);
  }


  const router = useRouter();
  const [alreadyRouted, setAlreadyRouted] = useState(false);
  useEffect(() => {
    if (!alreadyRouted) {
      let anchorIndex = router.asPath.indexOf('#');
      if (anchorIndex != -1) {
        let anchor = router.asPath.substring(anchorIndex, router.asPath.length);
        let tab = document.querySelector(anchor);
        if (tab != null) { tab.click() }
      }

      setAlreadyRouted(true);
    }
  }, [])

  return (
    <div className="col-span-2 lg:col-span-12 pt-[20px] xl:pt-[60px] px-20 lg:px-0">
      {id === 5 ? (
        <LeftCardForm leftSide={leftSide} showrooms={showrooms} systems={systems} />
      ) : (
        <RightCardForm formId={formId} />
      )}
    </div>
  );
};

export default WrapperFormCard;