import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";

import CategoryTag from "./CategoryTag";
import Btn from "../../commons/btn";

const NewsSlide = ({ news, index }) => {
  const { title, uri, featuredImage, tags} = news;

  const scope = useRef();
  const content = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(content.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*index
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <Link
      href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
      className={`block news-slider pb-10 ${index%2 == 0 ? `xs:translate-y-25` : `xs:-translate-y-25`}`}
      ref={scope}
    >
      <div ref={content}>
        <div className="aspect-square w-full relative mb-10 rounded-[10px] overflow-hidden">
          <Image
            src={featuredImage?.node?.sourceUrl}
            sizes={featuredImage?.node?.sizes}
            fill={true}
            className="object-cover"
            alt={title}
          />
          {!isEmpty(tags) && (
            <div className="absolute left-10 bottom-10 flex items-center">
              {tags?.nodes?.map(tag => (
                <CategoryTag key={v4()} category={tag} />
              ))}
            </div>
          )}
        </div>
        <h3 className="h5">{title}</h3>
        <Btn
          type="cta-light-2"
          title="Lire l'article"
          classes={`text-primary mt-20`}
          textClasses={`decoration-primary decoration-1`}
        />
      </div>
    </Link>
  )
}

export default NewsSlide