import React, { useEffect, useState } from "react";

// Imports des blocs utilisés
import BlockHpIntroduction from "../blocks/BlockHpIntroduction";
import BlockHpSliderTechnologyv2 from "../blocks/BlockHpSliderTechnologyv2";
import BlockHpWhybl from "../blocks/BlockHpWhybl";
import BlockHpForWho from "../blocks/BlockHpForWho";
import BlockHpBlSystems from "../blocks/BlockHpBlSystems";
import BlockHpRelaxProgramms from "../blocks/BlockHpRelaxProgramms";
import BlockHpBanner from "../blocks/BlockHpBanner";
import BlockPartners from "../blocks/BlockPartners";
import BlockHpCompanyBanner from "../blocks/BlockHpCompanyBanner";
import BlockHpNews from "../blocks/BlockHpNews";
import BlockCommonHeader from "../blocks/BlockCommonHeader";
import BlockCommonImgTxt from "../blocks/BlockCommonImgTxt";
import BlockPageBanner from "../blocks/BlockPageBanner";
import BlockPageTeam from "../blocks/BlockPageTeam";
import BlockPageAwards from "../blocks/BlockPageAwards";
import BlockRewardsDetails from "../blocks/BlockRewardsDetails";
import BlockPageMapReseller from "../blocks/BlockPageMapReseller";
import BlockPageNews from "../blocks/BlockHpNews";
import BlockPageBannerBsb from "../blocks/BlockPageBannerBsb";
import BlockPageTeamBig from "../blocks/BlockPageTeamBig";
import BlockPageSliderCommercialAgents from "../blocks/BlockPageSliderCommercialAgents";
import BlockListingTestimonies from "../blocks/BlockListingTestimonies";
import BlockPageForm from "../blocks/BlockPageForm";
import BlockBlExperience from "../blocks/BlockBlExperience";
import BlockProductsMea from "../blocks/BlockProductsMea";
import BlockArmchairsmea from "../blocks/BlockArmchairsmea";
import BlockArchiveRelaxProgramms from "../blocks/BlockArchiveRelaxProgramms";
import BlockBannerAccessories from "../blocks/BlockBannerAccessories";
import BlockListingArmchairs from "../blocks/BlockListingArmchairs";
import BlockSubmenu from "../blocks/BlockSubmenu";
import BlockHeaderArmchair from "../blocks/BlockHeaderArmchair";
import BlockContent from "../blocks/BlockContent";
import BlockImageGallery from "../blocks/BlockImageGallery";
import BlockTitleText from "../blocks/BlockTitleText";
import BlockColorChoice from "../blocks/BlockColorChoice";
import BlockVideo from "../blocks/BlockVideo";
import BlockTechnologies from "../blocks/BlockTechnologies";
import BlockReassurance from "../blocks/BlockReassurance";
import BlockCtaTest from "../blocks/BlockCtaTest";
import BlockOthersProgramms from "../blocks/BlockOthersProgramms";
import BlockOthersArmchairs from "../blocks/BlockOthersArmchairs";
import BlockHeaderProgramm from "../blocks/BlockHeaderProgramm";
import BlockProgrammPresentation from "../blocks/BlockProgrammPresentation";
import BlockBannerWTittle from "../blocks/BlockBannerWTittle";
import BlockInfiniteCards from "../blocks/BlockInfiniteCards";
import BlockComparator from "../blocks/BlockComparator";
import BlockHeaderBlog from "../blocks/BlockHeaderBlog";
import BlockListingPosts from "../blocks/BlockListingPosts";
import BlockWysiwyg from "../blocks/BlockWysiwyg";
import BlockPostBanner from "../blocks/BlockPostBanner";
import BlockPostIntroduction from "../blocks/BlockPostIntroduction";
import BlockPostContentImgRight from "../blocks/BlockPostContentImgRight";
import BlockPostContentImgLeft from "../blocks/BlockPostContentImgLeft";
import BlockAccessoriesHeader from "../blocks/BlockAccessoriesHeader";
import BlockOtherAccessories from "../blocks/BlockOtherAccessories";
import BlockLisitingAccessories from "../blocks/BlockLisitingAccessories";
import BlockMap from "../blocks/BlockMap";
import BlockBuyButtons from "../blocks/BlockBuyButtons";
import Footer from "../footer";


const Block = ({blockData, breadcrumbs, templateWhite, pt_key, category, armchairTagLine, productFeaturedImage, programmInfo, tags, posts, postFeaturedImage, programms, partners, testimonies, lastnews, armchairs, accessories, teamMembers, commercialAgents, testimoniesForListing, showrooms, comparatorDatas, postTags, systemTagLine, titleFont, systems, logos, optionsFooter}) => {

  const components = {
    [pt_key + '_BlockHpIntroduction']: BlockHpIntroduction,
    [pt_key + '_BlockSliderTechnology']: BlockHpSliderTechnologyv2,
    [pt_key + '_BlockHpWhybl']: BlockHpWhybl,
    [pt_key + '_BlockForWho']: BlockHpForWho,
    [pt_key + '_BlockBrainlightSystems']: BlockHpBlSystems,
    [pt_key + '_BlockRelaxProgramms']: BlockHpRelaxProgramms,
    [pt_key + '_BlockHpBanner']: BlockHpBanner,
    [pt_key + '_BlockPartners']: BlockPartners,
    [pt_key + '_BlockCompanyBanner']: BlockHpCompanyBanner,
    [pt_key + '_BlockHpNews']: BlockHpNews,
    [pt_key + '_BlockHeader']: BlockCommonHeader,
    [pt_key + '_BlockImageText']: BlockCommonImgTxt,
    [pt_key + '_BlockPageBanner']: BlockPageBanner,
    [pt_key + '_BlockPageTeam']: BlockPageTeam,
    [pt_key + '_BlockPageAwards']: BlockPageAwards,
    [pt_key + '_BlockRewardsDetails']: BlockRewardsDetails,
    [pt_key + '_BlockMapReseller']: BlockPageMapReseller,
    [pt_key + '_BlockPageNews']: BlockPageNews,
    [pt_key + '_BlockPageBannerBsb']: BlockPageBannerBsb,
    [pt_key + '_BlockPageTeamBig']: BlockPageTeamBig,
    [pt_key + '_BlockPageSliderCommercialAgents']: BlockPageSliderCommercialAgents,
    [pt_key + '_BlockListingTestimonies']: BlockListingTestimonies,
    [pt_key + '_BlockForm']: BlockPageForm,
    [pt_key + '_BlockBlExperience']: BlockBlExperience,
    [pt_key + '_BlockProductsMea']: BlockProductsMea,
    [pt_key + '_BlockArmchairsMea']: BlockArmchairsmea,
    [pt_key + '_BlockArchiveRelaxProgramms']: BlockArchiveRelaxProgramms,
    [pt_key + '_BlockBannerAccessories']: BlockBannerAccessories,
    [pt_key + '_BlockListingArmchairs']: BlockListingArmchairs,
    [pt_key + '_BlockProductSubmenu']: BlockSubmenu,
    [pt_key + '_BlockHeaderArmchair']: BlockHeaderArmchair,
    [pt_key + '_BlockContenu']: BlockContent,
    [pt_key + '_BlockImageGallery']: BlockImageGallery,
    [pt_key + '_BlockTitleText']: BlockTitleText,
    [pt_key + '_BlockColorChoice']: BlockColorChoice,
    [pt_key + '_BlockVideo']: BlockVideo,
    [pt_key + '_BlockTechnologies']: BlockTechnologies,
    [pt_key + '_BlockReassurance']: BlockReassurance,
    [pt_key + '_BlockCtaTest']: BlockCtaTest,
    [pt_key + '_BlockOtherProgramms']: BlockOthersProgramms,
    [pt_key + '_BlockOthersArmchairs']: BlockOthersArmchairs,
    [pt_key + '_BlockHeaderProgramm']: BlockHeaderProgramm,
    [pt_key + '_BlockProgrammPresentation']: BlockProgrammPresentation,
    [pt_key + '_BlockBannerWTitle']: BlockBannerWTittle,
    [pt_key + '_BlockInfiniteCard']: BlockInfiniteCards,
    [pt_key + '_BlockCompare']: BlockComparator,
    [pt_key + '_BlockHeaderBlog']: BlockHeaderBlog,
    [pt_key + '_BlockListingPosts']: BlockListingPosts,
    [pt_key + '_BlockWysiwyg']: BlockWysiwyg,
    [pt_key + '_BlockPostBanner']: BlockPostBanner,
    [pt_key + '_BlockPostIntroduction']: BlockPostIntroduction,
    [pt_key + '_BlockPostContentImgRight']: BlockPostContentImgRight,
    [pt_key + '_BlockPostContentImgLeft']: BlockPostContentImgLeft,
    [pt_key + '_BlockHeaderAccessories']: BlockAccessoriesHeader,
    [pt_key + '_BlockOtherAccessories']: BlockOtherAccessories,
    [pt_key + '_BlockListingAccessories']: BlockLisitingAccessories,
    [pt_key + '_BlockMap']: BlockMap,
    [pt_key + '_BlockBuyButtons']: BlockBuyButtons
  }


  function BlockWrapper({ componentName, ...props}) {
    const Component = components[componentName];
    if (!Component) return null;

    // add index for block order
    props.blockInfo.index = props.index;
    // add template informations
    props.blockInfo.templateWhite = templateWhite;
    // add breadcrumbs if needed
    if ( componentName == pt_key + '_BlockHeader'
      || componentName == pt_key + '_BlockHeaderArmchair'
      || componentName == pt_key + '_BlockHeaderProgramm'
      || componentName == pt_key + '_BlockHeaderBlog'
      || componentName == pt_key + '_BlockHeaderAccessories') {
      props.blockInfo.breadcrumbs = breadcrumbs;
    }
    // add featuredImage if needed
    if ( componentName == pt_key + '_BlockHeaderArmchair'
      || componentName == pt_key + '_BlockHeaderProgramm'
      || componentName == pt_key + '_BlockHeaderAccessories') {
      props.blockInfo.productFeaturedImage = productFeaturedImage;
    }
    // add armchair tagline in needed
    if ( componentName == pt_key + '_BlockHeaderArmchair') {
      props.blockInfo.armchairTagLine = armchairTagLine;
    }
    // add system tagline in needed
    if ( componentName == pt_key + '_BlockHeaderAccessories') {
      props.blockInfo.systemTagLine = systemTagLine;
    }
    // add category id needed
    if ( componentName == pt_key + '_BlockProductSubmenu' ) {
      props.blockInfo.category = category;
    }
    // add programmInfo if needed
    if ( componentName == pt_key + '_BlockHeaderProgramm') {
      props.blockInfo.programmInfo = programmInfo;
    }
    // add tags & posts if needed
    if (componentName == pt_key + '_BlockListingPosts') {
      props.blockInfo.tags = tags;
      props.blockInfo.posts = posts;
    }
    // add tags & featured image if needed
    if (componentName == pt_key + '_BlockPostIntroduction') {
      props.blockInfo.postFeaturedImage = postFeaturedImage;
      props.blockInfo.postTags = postTags;
    }
    // Add programms if needed
    if (componentName == pt_key + '_BlockRelaxProgramms'
       || componentName == pt_key + '_BlockArchiveRelaxProgramms'
       || componentName == pt_key + '_BlockProductSubmenu') {
      props.blockInfo.programms = programms;
    }
    // Add systems if needed
    if (componentName == pt_key + '_BlockProductSubmenu'
      || componentName == pt_key + '_BlockForm') {
      props.blockInfo.systems = systems;
    }
    // Add partners & testimonies if needed
    if (componentName == pt_key + '_BlockPartners') {
      props.blockInfo.partners = partners;
      props.blockInfo.testimonies = testimonies;
    }
    // Add lastnews if needed
    if (componentName == pt_key + '_BlockHpNews'
      || componentName == pt_key + '_BlockProductSubmenu'
      || componentName == pt_key + '_BlockPageNews') {
      props.blockInfo.lastnews = lastnews;
    }
    // Add armchairs if needed
    if (componentName == pt_key + '_BlockListingArmchairs'
      || componentName == pt_key + '_BlockProductSubmenu') {
      props.blockInfo.armchairs = armchairs;
    }
    // Add accessories if needed
    if (componentName == pt_key + '_BlockListingAccessories'
      || componentName == pt_key + '_BlockProductSubmenu') {
      props.blockInfo.accessories = accessories;
    }
    // Add team members if needed
    if (componentName == pt_key + '_BlockPageTeam'
      || componentName == pt_key + '_BlockPageTeamBig') {
      props.blockInfo.teamMembers = teamMembers;
    }
    // Add commercial agents if needed
    if (componentName == pt_key + '_BlockPageSliderCommercialAgents') {
      props.blockInfo.commercialAgents = commercialAgents;
    }
    // Add testimonies for listing if needed
    if (componentName == pt_key + '_BlockListingTestimonies') {
      props.blockInfo.testimoniesForListing = testimoniesForListing;
    }
    // Add showrooms for listing if needed
    if (componentName == pt_key + '_BlockMap'
      || componentName == pt_key + '_BlockForm') {
      props.blockInfo.showrooms = showrooms;
    }
    // Add comaprator datas if needed
    if (componentName == pt_key + '_BlockCompare') {
      props.blockInfo.comparatorDatas = comparatorDatas;
    }
    // add fontTitle if need
    if (componentName == pt_key + '_BlockHeaderArmchair') {
      props.blockInfo.titleFont = titleFont;
    }

    if (props.index + 1 != blockData.length) {
      return <Component {...props} />;
    } else {
      return (
        <>
          <Component {...props} />
          <Footer logos={logos} templateWhite={templateWhite} optionsFooter={optionsFooter}/>
        </>
      )
    }
  }

  return (
    <div className="blocks relative z-10">
      {blockData.map( (item, index) => (
        <BlockWrapper
          key={`${item.fieldGroupName}-${index}`}
          index={index}
          componentName={item.fieldGroupName}
          blockInfo={item}
          breadcrumbs={breadcrumbs}
          templateWhite={templateWhite}
          productFeaturedImage={productFeaturedImage}
        />
      ))}
    </div>
  )
}

export default Block;