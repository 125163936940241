import { v4 } from "uuid";

import { useLayoutContext, useRefsContext } from "../../../contexts";

import { useEffect, useRef } from "react";

import Breadcrumbs from "../commons/Breadcrumb";
import Heading from "../commons/Heading";
import HeaderProgrammCard from "./partials/HeaderProgrammCard";
import Wysiwyg from "../commons/Wysiwyg";
import Btn from "../commons/btn";

const BlockHeaderProgramm = ({ blockInfo }) => {
  const {index, title, introduction, presentation, breadcrumbs, productFeaturedImage, programmInfo, backgroundEffects} = blockInfo;
  const { reloadFlag } = useLayoutContext();

  useEffect(() => {

  }, [reloadFlag])

  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  return (
    <section className={`
      block-header-programm block-index-${index} relative z-10 lb-block-padding-bottom
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-12 lg:col-span-3 xl:col-span-2 mb-40 pt-20 xl:pt-40">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 lg:col-span-5 xl:col-span-5">
            <Heading tag="h1" text={title} classes={"h2 text-white text-center lg:text-left"} animate={false} />
            {reloadFlag == 'mobile' && (
              <HeaderProgrammCard  programmInfo={programmInfo} image={productFeaturedImage} />
            )}
            <Heading tag="h2" text={introduction} classes={"h5 text-white my-30 lg:mt-0"} animate={false} />
            <Wysiwyg content={presentation} classes={'mb-30 text-white'} />
            <div className="text-center lg:text-left">
              <Btn type="normal-test" classes={'inline-flex mb-20'} /><br />
              <Btn type="cta-5" title={'Voir les caractéristiques'}  />
            </div>
          </div>

          {reloadFlag == 'desktop' && (
            <div className="lg:col-span-4 xl:col-start-9">
              <HeaderProgrammCard  programmInfo={programmInfo} image={productFeaturedImage} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default BlockHeaderProgramm