import { v4 } from "uuid"

const ComparatorCol = ({ product, index }) => {

  return (
    <div className={`flex-[0_0_310px] xl:flex-[0_0_23.5%] block bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] px-20 py-30 lg:px-40 overflow-hidden`}>
      {product?.acfArmchairs?.comparator?.[index]?.lines?.map(carac => (
        <div
          key={v4()}
          className="h-90 flex items-center justify-center border-b border-white/30 pb-30 mb-30 last:border-0 last:pb-0 last:mb-0
        ">
          {carac.none ? (
            <div className="part-none" />
          ): (
            <p className="mb-0 text-center text-white px-10">{carac.text}</p>
          )}
        </div>
      ))}
    </div>
  )
}

export default ComparatorCol