import Cf7FormWrapper from "../../forms/cf7-form-wrapper";
import FormTest from "../../forms/formTest";
import FormTestLocation from "../../forms/formTestLocation";

const FormControllerLeftSide = ({formId, showrooms, systems}) => {
  const regex = /\[contact-form-7 id="(\d+)"\s*title=".*"]/;
  const matches = formId.match(regex);
  let id = null;
  if (matches && matches.length > 1) {
    id = parseInt(matches[1]);
  }
  if (id == 5) {
    return <Cf7FormWrapper formId={id} showrooms={showrooms}><FormTest key={formId} /></Cf7FormWrapper>
  } else if(id == 497) {
    return <Cf7FormWrapper formId={id} systems={systems}><FormTestLocation key={formId} /></Cf7FormWrapper>
  }
  return (
    <div>
      {formComponent && (
        <Cf7FormWrapper>
          {formComponent}
        </Cf7FormWrapper>
      )}
    </div>
  )
}

export default FormControllerLeftSide
