import { gql } from "@apollo/client";
import MediaFragment from "../fragments/media";

export const GET_FILTERED_POSTS = gql`
  query GetFilteredPosts($tagId: String, $after: String) {
    posts(
      first: 6
      where: {tagId: $tagId}
      after: $after
    ) {
      nodes {
        title
        uri
        featuredImage {
          node {
            ...MediaFragment
          }
        }
        tags {
          nodes {
            name
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${MediaFragment}
`