import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";

import Heading from "../../commons/Heading";
import Btn from "../../commons/btn";

const AccessoryCard = ({ index, product }) => {
  const scope = useRef();

  if (index >= 4) {
    useEffect(() => {
      gsap.registerPlugin(ScrollTrigger);

      let ctx = gsap.context(() => {
        gsap.from(scope.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0,
          delay: 0.1*index
        })
      }, [scope])

      return () => {
        ctx.revert();
      }
    }, [])
  }

  return (
    <Link
      href={product?.uri}
      className={`group block h-full bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] overflow-hidden`}
      ref={scope}
    >
      <div className="h-full flex flex-col items-center justify-center gap-y-15 p-15">
        <Image
          src={product?.featuredImage?.node?.sourceUrl}
          width={product?.featuredImage?.node?.mediaDetails.width}
          height={product?.featuredImage?.node?.mediaDetails.height}
          alt={product?.title}
          className="rounded-[10px]"
        />
        <Heading tag="h3" text={product?.title} classes={"h5 text-white"} animate={false} />
        <Btn type={'cta-2-2-group'} classes={'mt-auto w-full'} title={'Voir le produit'} />
      </div>
    </Link>
  )
}

export default AccessoryCard
