import Lottie from "lottie-react";
import whiteLoader from "../../../public/lottie/white-loader.json";
import blueLoader from "../../../public/lottie/blue-loader.json";

const Loader = ({color = 'white', classes }) => {
  let loader = whiteLoader;
  if (color != 'white') {
    loader = blueLoader;
  }

  return (
    <div className={`w-full ${classes}`}>
      <Lottie animationData={loader} loop={true} className="w-full h-full" />
    </div>
  )
}

export default Loader