import { isEmpty } from "lodash";
import { v4 } from "uuid";

import CardWhybl from "./partials/CardWhybl";
import Heading from "../commons/Heading";
import CardWhyblAlt from "./partials/CardWhyblAlt";

const BlockReassurance = ({ blockInfo}) => {
  const { index, title, reassuranceItems } = blockInfo;

  return (
    <section className={`block-reassurance block-index-${index} fb-block-padding-top lb-block-padding-bottom`}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className={`
            col-span-2 mb-50 text-center
            xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3
            md:col-span-10 md:col-start-2
            ${reassuranceItems?.length == 3 ? 'md:mb-130' : 'lg:mb-90'}
          `}>
            <Heading tag="h2" text={title} classes="text-white h4" />
          </div>
        </div>
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          {!isEmpty(reassuranceItems) && reassuranceItems?.map((item, index) => (
            reassuranceItems?.length == 3 ? (
              <CardWhybl key={v4()} item={item} index={index} />
            ) : (
              <CardWhyblAlt key={v4()} item={item} index={index} />
            )
          ))}
        </div>
      </div>
    </section>
  )
}

export default BlockReassurance