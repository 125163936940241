import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"

import Link from "next/link";
import Image from "next/image";

const PartnersSlider = ({ bw, partners }) => {
  if (isEmpty(partners)) { return null; }

  return (
    <div className="partners-slider">
      <div>
        {!isEmpty(partners) && (
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            spaceBetween={0}
            loop={true}
            autoplay={true}
            breakpoints={{
              768: {
                slidesPerView: 4.75,
                slidesPerGroup: 1,
                spaceBetween: 30,
              }
            }}
            className="!overflow-visible"
          >
            {partners.map(partner => (
              <SwiperSlide key={v4()}>
                {isEmpty(partner?.link?.url) ? (
                  <div>
                    <Image
                      src={partner?.logo?.sourceUrl}
                      width={partner?.logo?.mediaDetails?.width}
                      height={partner?.logo?.mediaDetails?.height}
                      alt={'Logo partenaire'}
                      className={`${bw && `grayscale opacity-80`} mx-auto`}
                    />
                  </div>
                ) : (
                  <Link
                    title={partner?.link?.title}
                    href={partner?.link?.url}
                    target={partner?.link?.target}
                  >
                    <Image
                      src={partner?.logo?.sourceUrl}
                      width={partner?.logo?.mediaDetails?.width}
                      height={partner?.logo?.mediaDetails?.height}
                      alt={partner?.link?.title}
                      className={`${bw && `grayscale opacity-80`} mx-auto`}
                    />
                  </Link>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

export default PartnersSlider