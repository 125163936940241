import { isEmpty } from "lodash";
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = 'pk.eyJ1IjoibXljbGllbnRpc3JpY2giLCJhIjoiY2xpYWY5OG5jMDMydDNxdGVlYmZpcThtbiJ9.TrupB7R4swgTcSY4ZCFqKQ';


import {useRef, useEffect} from "react"

import Btn from "../commons/btn";
import Heading from "../commons/Heading";

const BlockPageMapReseller = ({ blockInfo }) => {
  const { index, title, text, link, mapConfig } = blockInfo;

  const mapContainer = useRef(null);
  const map = useRef(null);

  if (mapConfig.longitude == '') {
    mapConfig.longitude = 3.8288506;
  }
  if (mapConfig.latitude == '') {
    mapConfig.latitude = 47.1743864;
  }
  if (mapConfig.zoom == '') {
    mapConfig.zoom = 6.61;
  }

  useEffect(() => {
    if (!map.current) {
      //init
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/myclientisrich/clg2f9s3k00a801qhr3zlppyz',
        center: [mapConfig.longitude, mapConfig.latitude],
        zoom: mapConfig.zoom,
        minzoom: 5,
        maxzoom: 12,
        pitch: 12,
        interactive: true,
        passive: true
      });

      map.current.scrollZoom.disable();
    }
  }, []);

  return (
    <section className={`
      block-page-map-reseller block-index-${index} relative z-10
      h-[calc(100vh_-_90px)] lg:h-auto lg:aspect-[16/9] xl:aspect-[1512/595]
    `}>
      <div className="w-full h-full absolute top-0 left-0 z-[5] bg-map-linear-gradient"></div>
      <div
        id={`map-reselleter-${index}`}
        ref={mapContainer}
        className="w-full h-full relative z-[1] mix-blend-luminosity"
      />
      <div className="container absolute left-0 bottom-50 lg:bottom-80 xl:bottom-50 z-10">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="col-span-2 xs:col-span-8 xs:col-start-5 lg:col-span-5 lg:col-start-7 text-white text-right">
            <Heading tag="h2" text={title} classes="h3" />
            <p className="mt-40">{text}</p>
            {!isEmpty(link) && (
              <Btn
                type="cta-2-2"
                title={link.title}
                uri={link.url}
                target={link.target}
                classes={`mt-40 lg:mt-80`}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPageMapReseller