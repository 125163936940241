import { isEmpty } from "lodash";

import { useEffect } from "react";

import { useLayoutContext } from "../../../contexts";

import InfiniteCardDekstop from "./partials/InfiniteCard/InfiniteCardDekstop";
import InfiniteCardMobile from "./partials/InfiniteCard/InfiniteCardMobile";

const BlockInfiniteCards = ({ blockInfo }) => {
  const { index, items } = blockInfo;
  if (isEmpty(items)) {return;}

  const { reloadFlag } = useLayoutContext();

  useEffect(() => {

  }, [reloadFlag])

  return (
    <section className={`
      block-infinite-cards block-index-${index} common-block-padding overflow-hidden
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-12">
            {reloadFlag == 'mobile' ? (
              <InfiniteCardMobile cards={items} />
              ) : (
              <InfiniteCardDekstop cards={items} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockInfiniteCards