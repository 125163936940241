import { isEmpty } from "lodash";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Wysiwyg from "../commons/Wysiwyg";

const BlockWysiwyg = ({ blockInfo }) => {
  const { index, content } = blockInfo;
  if (isEmpty(content)) {return;}

  const scope = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-wysiwyg block-index-${index} common-block-padding
    `} ref={scope}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 lg:col-span-12">
            <Wysiwyg content={content} classes={'text-white'} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockWysiwyg