import { useState } from "react";

import WrapperFormCard from "./partials/WrapperFormCard";

const BlockPageForm = ({ blockInfo }) => {
  const { leftSide, rightSide, showrooms, systems } = blockInfo;
  const initialFormId = leftSide.addForm[0].idForm;

  const [formId, setFormId] = useState(initialFormId);
  const handleChangeForm = (newFormId) => {
    setFormId(newFormId)
  }

  const classses = `col-span-2 lg:col-span-6 text-center text-white
  title-onglet py-20 lg:py-26 rounded-t-[10px] hover:opacity-100 cursor-pointer ease-in duration-300
  `;
  return (
    <section className={`block-page-form relative z-10 common-block-padding`}>
      <div className="container">
        <div className={`bg-secondary rounded-t-[10px] grid grid-cols-mobile lg:grid-cols-normal
          ${formId === leftSide.addForm[0].idForm ? 'lg:rounded-tl-[10px]' : 'lg:rounded-tr-[10px]'}`}
        >
          <div className={` inline-flex items-center justify-center hover:opacity-100 cursor-pointer ${classses}
            ${formId === leftSide.addForm[0].idForm ? 'bg-secondary' : 'bg-primary opacity-50'}`}
            onClick={() => handleChangeForm(leftSide.addForm[0].idForm)}>
              <span className="icon-blink-2 text-white mr-20"></span>
              <span className="title-card">{leftSide.title}</span>
              <span className="icon-blink-2 text-white ml-20"></span>
          </div>
          <div id="informations" className={`${classses}
            ${formId === rightSide.formId ? 'bg-secondary' : 'bg-primary opacity-50'}`}
            onClick={() => handleChangeForm(rightSide.formId)}>
              {rightSide.title}
          </div>

          <WrapperFormCard leftSide={leftSide} formId={formId} showrooms={showrooms} systems={systems} />

        </div>
      </div>
    </section>
  )
}

export default BlockPageForm;
