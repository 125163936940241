import RightRelative from './RightRelative';
import RightAbsolute from './RightAbsolute';

const ContentRightImg = ({blockInfo}) => {
  const {configuration} = blockInfo;

  if(configuration.imgAbsolute == "relative") {
    return <RightRelative blockInfo={blockInfo} />
  } else {
    return <RightAbsolute blockInfo={blockInfo} />
  }
}

export default ContentRightImg