import { isEmpty } from "lodash";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";

import Heading from "../../commons/Heading";
import Btn from "../../commons/btn";

const ArmchairCard = ({ index, product, animate, classes, hideExcerpt = false }) => {
  const scope = useRef();

  if (animate) {
    useEffect(() => {
      gsap.registerPlugin(ScrollTrigger);

      let ctx = gsap.context(() => {
        gsap.from(scope.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0,
          delay: 0.1*index
        })
      }, [scope])

      return () => {
        ctx.revert();
      }
    }, [])
  }


  return (
    <Link
      href={product?.uri}
      className={`armchair-card group block bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] overflow-hidden h-full ${classes}`}
      ref={scope}
    >
      <div className="h-full flex flex-col items-center justify-center gap-y-15 p-40">
        <Image
          src={product?.featuredImage?.node?.sourceUrl}
          width={product?.featuredImage?.node?.mediaDetails.width}
          height={product?.featuredImage?.node?.mediaDetails.height}
          alt={product?.title}
        />
        <Heading tag="h3" text={product?.title} classes={`text-white h4 font-${product?.acfArmchairs?.titleFont}`} animate={false} />
        {!isEmpty(product?.acfArmchairs?.excerpt) && !hideExcerpt && (
          <p className="text-center text-white">{product?.acfArmchairs?.excerpt}</p>
        )}
        <Btn type={'cta-2-2-group'} classes={'mt-auto'} title={'Voir le produit'} />
      </div>
    </Link>
  )
}

export default ArmchairCard