import parse from 'html-react-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";

const TechnologyCard = ({ item, index }) => {
  const {title, content, image} = item;

  const handleClick = event => {
    let icon = event.currentTarget.querySelector('.icon');
    let content = event.currentTarget.previousElementSibling;

    if (icon.classList.contains('icon-plus')) {
      gsap.to(content, {height: 'auto'})
      icon.classList.remove('icon-plus')
      icon.classList.add('icon-minus')
      content.classList.add('mb-15')
    } else {
      gsap.to(content, {height: 0})
      icon.classList.remove('icon-minus')
      icon.classList.add('icon-plus')
      content.classList.remove('mb-15')
    }
  }

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*(index%4)
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])


  return (
    <div className="technology-card flex flex-col bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] p-25 4xl:p-50" ref={scope}>
      <Image
        src={image?.sourceUrl}
        width={image?.mediaDetails?.width}
        height={image?.mediaDetails?.height}
        alt={title}
        className="mx-auto h-120 w-auto"
      />
      <div className="title flex items-center justify-center
        mt-25 mb-15 4xl:mt-30 6xl:mb-20 5xl:mt-35 6xl:mb-25 6xl:mt-40 6xl:mb-30
        ft-20 font-light text-center text-white
      "><span>{title}</span></div>
      <div className="mt-auto">
        {content != null && (
          <>
            <div className="content h-0 overflow-hidden wysiwyg text-center text-white">{parse(content)}</div>
            <div
            className="group w-26 h-26 mx-auto border border-white rounded-full flex items-center justify-center hover:cursor-pointer hover:border-action"
            onClick={handleClick}
            >
              <span className="icon icon-plus text-white ft-12 group-hover:text-action"></span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default TechnologyCard