import { isEmpty } from "lodash";
import parse from 'html-react-parser';

// GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react"

import Image from "next/image"
import Link from "next/link"

import Heading from "../../../commons/Heading"
import Btn from "../../../commons/btn"

const ClassicStyleCard = ({product, nPerLine, index}) => {
  if (isEmpty(product)) { return null; }
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*index
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  let colClasses = 'md:col-span-6';
  if (nPerLine == 3) {
    colClasses = 'md:col-span-8 md:col-start-3 lg:col-span-4'
  }


  return (
    <Link href={product?.uri} className={`col-span-2 xs:col-span-10 xs:col-start-2 ${colClasses}`} ref={scope}>
      <div className="w-full aspect-[644/795] relative rounded-[10px] overflow-hidden">
        <Image
          src={product?.featuredImage?.node?.sourceUrl}
          sizes={product?.featuredImage?.node?.sizes}
          fill={true}
          className="object-cover z-10"
          alt={product?.title}
        />
        <div className="w-full h-full absolute z-20 bg-[#031218]/60"/>
        <div className="w-full h-full absolute z-30 flex flex-col gap-y-15 items-center justify-center lg:gap-y-30">
          <Heading tag="h3" text={product?.title} classes={"h4 text-white"} animate={false} />
          {!isEmpty(product?.acfSystems?.excerpt) ? <p className="text-center text-white">{parse(product.acfSystems.excerpt)}</p> : null}
          <Btn type={'cta-2-2'} title={'Voir le produit'} />
        </div>
      </div>
    </Link>
  )
}

export default ClassicStyleCard