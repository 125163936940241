import { isEmpty } from "lodash";
import { v4 } from "uuid";

import client from "../../utils/apollo/client";
import { GET_FILTERED_POSTS } from "../../queries/posts/get-filtered-posts";

import { useEffect, useState } from "react";

import NewsTagsList from "./partials/NewsTagsList";
import Btn from "../commons/btn";
import BlogPost from "./partials/BlogPost";

const BlockListingPosts = ({blockInfo}) => {
  const { index, tags, posts } = blockInfo;

  const [postsToDisplay, setPostsToDisplay] = useState(false);
  const [activeTag, setActiveTag] = useState('');
  const [showLoadMore, setShowLoadMore] = useState(posts.pageInfo.hasNextPage);
  const [cursor, setCursor] = useState(posts.pageInfo.endCursor);
  const [isLoading, setIsLoading] = useState(false);

  const fetchArticles = async (tagId, cleanPosts) => {
    setIsLoading(true);
    const { data } = await client.query({
      query: GET_FILTERED_POSTS,
      variables: {
        tagId: tagId,
        after: cleanPosts == true ? null : cursor
      }
    });


    if (postsToDisplay == false || cleanPosts == true ) {
      setPostsToDisplay(data.posts.nodes);
    } else {
      setPostsToDisplay(prevPostsToDisplay => [...prevPostsToDisplay, ...data.posts.nodes]);
    }
    setCursor(data.posts.pageInfo.endCursor);
    setShowLoadMore(data.posts.pageInfo.hasNextPage);
    setActiveTag(tagId);
    setIsLoading(false);
  }

  const handleSearch = (event) => {
    let clicked = event.currentTarget;
    if (clicked != undefined) {
      let tagId = clicked.getAttribute('data-tagid');
      fetchArticles(tagId, true)
        .then(() => {
          let tags = clicked.parentNode;
          let prevActive = tags?.querySelector('.active');
          if (prevActive != undefined) {
            prevActive.classList.remove('active');
          }
          clicked.classList.add('active');
        });
    }
  }

  const handleLoadMore = () => {
    fetchArticles(activeTag, null);
  };

  useEffect(() => {
    if (postsToDisplay == false) {
      setPostsToDisplay(posts.nodes);
    }
  }, []);

  return (
    <section className={`
      block-listing-posts block-index-${index} common-block-padding-bottom
    `}>
      <div className="border-y border-white/30 py-15">
        <div className="container">
          <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
            <div className="col-span-2 xs:col-span-12">
              <NewsTagsList tags={tags} activeTag={activeTag} handleSearch={handleSearch} isLoading={isLoading}  />
            </div>
          </div>
        </div>
      </div>

      <div className="container fb-block-padding">
        <div className="grid grid-cols-mobile gap-x-mobile gap-y-40 xs:grid-cols-normal lg:gap-x-normal lg:gap-y-80">
            {!isEmpty(postsToDisplay) && (postsToDisplay.map((post, index) => (
              <BlogPost key={v4()} post={post} index={index} />
            )))}
          <div className="col-span-2 xs:col-span-12 text-center lg:mt-50 4xl:mt-100 ">
            <div className={`${!showLoadMore ? 'hidden' : 'inline-block'}`} onClick={handleLoadMore}>
              <Btn type='cta-2-2' title='Voir plus' iconClasses={'rotate-90'} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockListingPosts