import { isEmpty } from 'lodash';

import BuyButton from '../commons/BuyButton';

const BlockBuyButtons = ({ blockInfo }) => {
  const { buyButtons, columnNumbers } = blockInfo;
  if (isEmpty(buyButtons)) { return null; }

  return (
    <div className={`block-buy-buttons common-block-padding`}>
      <div className="container">
        <div className={`grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal`}>
          {buyButtons?.map((button, index) => (
            <div key={index} className={`col col-mobile-${columnNumbers?.mobile} col-tablet-${columnNumbers?.tablet} col-desktop-${columnNumbers?.desktop}`}>
              <BuyButton code={button?.code} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BlockBuyButtons