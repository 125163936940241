import { useState } from 'react';

import Wysiwyg from '../../commons/Wysiwyg';

const Checkbox = ({ data, handleFieldChange }) => {
  const [ isChecked, setIsChecked ] = useState(false)

  const handleChange = event => {
    handleFieldChange(data.name, event);
  }

  const handleClick = (event) => {
    let input = event.currentTarget.querySelector('input');
    let custom = event.currentTarget.querySelector('.custom-checkbox');
    if (isChecked) {
      custom.classList.remove('checked');
    } else {
      custom.classList.add('checked');
    }
    setIsChecked(!isChecked)
    input.click();
  };

  return (
    <div className={`checkbox-wrapper ${data.classes}`}>
      <div className='flex gap-3 cursor-pointer items-center md:justify-center' onClick={handleClick}>
        <input
          type="checkbox" className={`hidden`} name={data?.name} checked={isChecked}
          onChange={handleChange} checked={false}
        />
        <div className={`custom-checkbox`} />
        <data.placeholder />
      </div>
      {data.invalid && <span className="pt-2 block text-red-500">{'fieldError'}</span>}
    </div>
  );
};
export default Checkbox;
