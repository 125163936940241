
import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { gsap } from "gsap";

import { useRef, useEffect } from "react"

import CardWhybl from "./partials/CardWhybl";
import ScrollingSentence from "./partials/ScrollingSentence";
import Heading from "../commons/Heading";

const BlockHpWhybl = ({ blockInfo }) => {
  const { index, suptitle, title, reassuranceItems, scrollingSentences } = blockInfo;

  const scope = useRef();
  const arrow = useRef();

  const handleClick = event => {
    let section = event.currentTarget.parentNode;
    let nextSection = section.nextElementSibling;

    if (nextSection != undefined) {
      let offset = document.querySelector('header')?.clientHeight;
      let y = nextSection.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({top: y, behavior: 'smooth'});
    }
  };

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(arrow.current, {y: 10, duration: 1, repeat: -1})
    }, scope)

    return (() => {
      ctx.revert();
    })
  }, [])

  return (
    <section className={`block-hp-whybl block-index-${index} relative z-10 fb-block-padding`} ref={scope}>
      <div className="container  pb-50 md:pb-80">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="
            col-span-2 mb-50 text-center
            xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3
            md:mb-130 md:col-span-10 md:col-start-2
          ">
            <Heading
              tag="div" text={suptitle}
              classes="suptitle mb-30 md:mb-20 text-white text-shadow-[0_0_3px_#0B212A]"
            />
            <Heading
              tag="div" text={title} titleWChild={true}
              classes="title-w-em text-white text-shadow-[0_0_3px_#0B212A]"
            />
          </div>
        </div>
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          {!isEmpty(reassuranceItems) && reassuranceItems.map((item, index) => (
            <CardWhybl key={v4()} item={item} index={index} />
          ))}
        </div>
      </div>
      <div className="mb-30 text-center" ref={arrow} onClick={handleClick}>
        <span className="icon icon-arrow-down text-[33px] 4xl:text-[42px] 5xl:text-[48px] 6xl:text-[55px] text-white"></span>
      </div>
      <ScrollingSentence sentences={scrollingSentences}/>
    </section>
  )
}

export default BlockHpWhybl