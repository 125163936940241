import { isEmpty } from "lodash";

import { useEffect, useRef, useState } from "react";

import ProgressBar from "./partials/ProgressBar";

const MultipleAudioComponent = ({ audios, listening, setIsListening, playing, setIsPlaying }) => {
  if (isEmpty(audios))
    return;

  const audioRef = useRef();
  const iconPlayRef = useRef();
  const [audioIndex, setAudioIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');


  const formatTime = (seconds) => {
    seconds = parseInt(seconds);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    const audio = audioRef.current;

    const onLoadedMetadata = () => setDuration(formatTime(audio.duration));
    const updateProgress = () => {
      const { currentTime, duration } = audio;
      setCurrentTime(formatTime(audio.currentTime))
      setProgress((currentTime / duration) * 100);
    };

    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    audio.addEventListener('timeupdate', updateProgress);
    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', onLoadedMetadata);
    };
  }, [listening])

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      iconPlayRef.current.classList.remove('icon-play-no-circle');
      iconPlayRef.current.classList.add('icon-pause');
      setIsPlaying(true);
    } else {
      audio.pause();
      iconPlayRef.current.classList.add('icon-play-no-circle');
      iconPlayRef.current.classList.remove('icon-pause');
      setIsPlaying(false);
    }
  };

  const handleBackward = () => {
    if (playing) { handlePlayPause(); }

    if (audioIndex == 0) {
      setIsListening(audios[audios.length - 1]);
      setAudioIndex(audios.length - 1);
    } else {
      setIsListening(audios[audioIndex - 1]);
      setAudioIndex(audioIndex - 1);
    }

    setTimeout(() => {
      handlePlayPause();
    }, 300);
  }

  const handleforward = () => {
    if (playing) { handlePlayPause(); }

    if (audioIndex == audios.length - 1) {
      setIsListening(audios[0]);
      setAudioIndex(0);
    } else {
      setIsListening(audios[audioIndex + 1]);
      setAudioIndex(audioIndex + 1);
    }

    setTimeout(() => {
      handlePlayPause();
    }, 300);
  }


  return (
    <div className="audio-component multiple flex-1 relative">
      <audio
        src={listening?.audio?.guid}
        preload="true"
        playsInline
        loop={true}
        ref={audioRef}
      />
      <div className="ac-wrapper flex items-center justify-between gap-x-10 px-20 py-10 absolute w-full bottom-0 border border-secondary rounded-full">
        <ProgressBar progress={progress} />
        <div className="flex items-center gap-x-10">
          <div className="time ft-15 font-light text-secondary">{currentTime} / {duration}</div>
          <div onClick={handleBackward}>
            <div className="icon-wrapper flex items-center justify-center w-30 h-30 border border-secondary rounded-full">
              <span className="icon icon-backward ft-11 !leading-none -translate-x-[1px]" />
            </div>
          </div>
          <div onClick={handlePlayPause}>
            <div className="icon-wrapper flex items-center justify-center w-30 h-30 border border-secondary rounded-full">
              <span className="icon icon-play-no-circle ft-11 !leading-none" ref={iconPlayRef} />
            </div>
          </div>
          <div onClick={handleforward}>
            <div className="icon-wrapper flex items-center justify-center w-30 h-30 border border-secondary rounded-full">
              <span className="icon icon-forward ft-11 !leading-none" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultipleAudioComponent