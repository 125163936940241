import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"
import 'swiper/css'

import { v4 } from "uuid"
import { isEmpty } from "lodash"
import { useEffect, useRef, useCallback } from 'react'

import TechnologySlide from './partials/TechnologySlide'
import TechnologyFrame from './partials/TechnologyFrame'

const BlockHpSliderTechnologyv2 = ({ blockInfo }) => {
  const { index, slides, frame } = blockInfo
  const swiperRef = useRef(null)
  const debounceRef = useRef(null)

  const scope = useRef(null)
  const pagi = useRef(null)

  const populateBullets = swiper => {
    if (swiper) {
      swiper.pagination.bullets.forEach((bullet, index) => {
        const el = document.createElement("span")
        el.classList.add('hidden', 'lg:block')
        el.innerHTML = (index + 1).toString().padStart(2, '0')
        bullet.append(el)
      })

      swiperRef.current = swiper
    }
  }

  return (
    <section
      className={`block-hp-slider-technology block-index-${index}`}
      ref={scope}
    >
      <div className="inside overflow-hidden h-screen">
        <Swiper
          modules={[Pagination]}
          slidesPerView={1}
          speed={500}
          spaceBetween={0}
          pagination={{
            el: '.block-hp-slider-technology .swiper-tech-pagination',
            type: 'bullets',
            clickable: true
          }}
          onAfterInit={populateBullets}
          className="relative h-full"
        >
          {!isEmpty(slides) && slides.map((slide, i) => (
            <SwiperSlide key={v4()} className="!h-full">
              <TechnologySlide slide={slide} index={i + 1} />
            </SwiperSlide>
          ))}

          <div className="absolute z-20 w-full bottom-[20px] left-0 lg:bottom-[unset] lg:top-[45%] lg:-translate-y-1/2">
            <div className="container">
              <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal lg:items-center">
                <div className="
                  col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:order-last
                  lg:order-first lg:col-span-1
                ">
                  <div className="
                    swiper-tech-pagination mb-50
                    md:mb-0 md:mt-50
                    lg:flex-col lg:items-start lg:mt-0
                  " ref={pagi} />
                </div>
                <div className="
                  col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3
                  md:col-span-6 md:col-start-4 lg:col-span-3 lg:col-start-10 2xl:col-span-2 2xl:col-start-11
                ">
                  <TechnologyFrame frame={frame} />
                </div>
              </div>
            </div>
          </div>
        </Swiper>
      </div>
    </section>
  )
}

export default BlockHpSliderTechnologyv2
