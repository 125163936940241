import { isEmpty } from "lodash";
import { v4 } from "uuid";

import Image from "next/image";

import Wysiwyg from "../commons/Wysiwyg";

const BlockRewardsDetails = ({ blockInfo }) => {
  const {index, rewards} = blockInfo;

  if (isEmpty(rewards))
    return;

  return (
    <section className={`
      block-rewards-details block-index-${index} lb-block-padding relative z-10
    `}>
      <div className="container">
        {rewards?.map((reward, index) => (
          <div key={v4()} className="
            grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:items-center lg:gap-x-normal

          ">
            <div className={`hidden md:block md:col-span-12 lg:col-span-10 lg:col-start-2 md:border-t md:border-secondary`} />
            <div className={`
              col-t col-span-2 xs:col-span-12 md:col-span-5 lg:col-span-4 lg:col-start-2
              border-t border-secondary md:border-0
            `}>
              <Image
                src={reward?.image?.sourceUrl}
                width={reward?.image?.mediaDetails?.width}
                height={reward?.image?.mediaDetails?.height}
                alt={reward?.image?.altText}
                className="w-full h-auto xs:max-w-[75%] xs:mx-auto md:max-w-none"
              />
            </div>
            <div className={`
              col-b col-span-2 xs:col-span-12 md:col-span-6 md:col-start-7 lg:col-span-5 lg:col-start-7
              ${index+1 == rewards.length && 'border-b border-secondary md:border-0'}
            `}>
              <Wysiwyg content={reward?.content} classes={'mt-40 md:mt-0'} />
            </div>
            <div className={`
              hidden md:block md:col-span-12 lg:col-span-10 lg:col-start-2
              ${index+1 == rewards.length && 'md:border-b md:border-secondary'}
            `} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default BlockRewardsDetails