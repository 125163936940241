import Image from "next/image"
import Heading from "../../commons/Heading"
import AudioComponent from "../../commons/AudioComponent"

const HeaderProgrammCard = ({programmInfo, image}) => {
  return (
    <div className={`
        header-programm-card relative flex flex-col items-center justify-between p-20 md:p-40 rounded-[10px] text-center
      `}
      style={{ background: programmInfo?.gradient }}
    >
      <Image
        src={image?.sourceUrl}
        sizes={image?.sizes}
        width={image?.mediaDetails?.width}
        height={image?.mediaDetails?.height}
        alt={image?.altText}
        className="mb-30 w-full h-auto"
      />
      <Heading tag="h2" text='Écoutez un extrait du programme' classes={'h5 text-white mb-25'} animate={false} />
      <AudioComponent audio={programmInfo.audio} />
    </div>
  )
}

export default HeaderProgrammCard