import { isEmpty } from "lodash";

import Link from "next/link";
import Image from "next/image";

import Heading from "../../../commons/Heading";
import Btn from "../../../commons/btn";

const ComparatorArmchairCard = ({ product, index }) => {

  const handleClick = () => {
    let gtagEvent = '';

    if (product?.title == "Zenesse") {
      gtagEvent = 'download_zenesse';
    } else if (product?.title == "SPHERE") {
      gtagEvent = 'download_sphere'
    } else if (product?.title == "GRAVITY+") {
      gtagEvent = 'download_gravity'
    } else if (product?.title == "Flow") {
      gtagEvent = 'download_flow'
    }

    if (gtagEvent != '') {
      window.dataLayer.push({
        event: gtagEvent
      });
    }
  }

  return (
    <div className={`
      flex-[0_0_310px] xl:flex-[0_0_23.5%] block bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] last:mr-20
    `}>
      <div className="relative flex flex-col items-center justify-center gap-y-15 lg:gap-y-20 p-20 lg:p-40">
        <div className="w-150 sm:w-130 aspect-square" />
        <Image
          src={product?.featuredImage?.node?.sourceUrl}
          width={product?.featuredImage?.node?.mediaDetails?.width}
          height={product?.featuredImage?.node?.mediaDetails?.height}
          alt={product?.title}
          className="w-auto h-200 absolute top-0 left-1/2 -translate-x-1/2 -translate-y-50 z-10"
        />
        <Heading tag="h2" text={product?.title} classes={"h5 text-white"} animate={false} />
        <Btn type={'cta-2-2'} uri={product?.uri} title={'Voir le produit'} />
        {!isEmpty(product?.acfArmchairs?.technicalSheet?.guid) && (
          <Link href={product.acfArmchairs.technicalSheet.guid} className="ft-12 text-white underline" target="_blank" onClick={handleClick}>
            Télécharger la fiche technique
          </Link>
        )}
      </div>
    </div>
  )
}

export default ComparatorArmchairCard