import ContentLeftImg from "./partials/content/ContentLeftImg";
import ContentRightImg from "./partials/content/ContentRightImg";

const BlockContent = ({ blockInfo }) => {
  const {configuration} = blockInfo;

  if(configuration.positionImg === "left") {
    return <ContentLeftImg blockInfo={blockInfo} />
  } else {
    return <ContentRightImg blockInfo={blockInfo} />
  }
}

export default BlockContent