import React, { cloneElement, useState } from "react"

const jsonToFormData = (json, formId) => {
  try {
    const randomNum = Math.floor(Math.random() * 1000); // Example random number
    const wpcf7UnitTag = `wpcf7-f${formId}-${randomNum}`;

    // Add the _wpcf7_unit_tag to your JSON object
    const modifiedJson = {
      ...json,
      _wpcf7_unit_tag: wpcf7UnitTag,
    };

    const data = new FormData();
    for (let k in modifiedJson) {
      data.append(k, modifiedJson[k]);
    }

    return data;
  } catch (error) {
    return null
  }
}

const ErrorMessage = () => {
  return (
    <div style={{ color: "red" }}>
      <strong>url</strong> or <strong>siteUrl</strong> and{" "}
      <strong>formId</strong> are mandatory attributes
    </div>
  )
}

const Cf7FormWrapper = ({ children, siteUrl, formId,  showrooms, systems }) => {
  const [isSent, setSent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(null);
  const [invalidFields, setInvalidFields] =  useState(null);

  const url = process.env.NEXT_PUBLIC_WORDPRESS_SITE;
  const apiUrl = `${url}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback/`

  const formSubmitHandler = (event, payload) => {
    event.preventDefault()

    setLoading(true)
    setError(null)

    fetch(apiUrl, {
      method: "POST",
      body: jsonToFormData(payload),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status !== "mail_sent") throw resp;

        // gtag
        let gtagEvent = '';
        if (formId == 5) { // Venez le tester en showroom
          gtagEvent = 'rdv_showroom';
        } else if (formId == 497) { // Louez un système dans votre entreprise
          gtagEvent = 'demande_location';
        } else if (formId == 479) { // demande d'informations
          gtagEvent = 'form_submitted';
        }
        if (gtagEvent != '') {
          window.dataLayer.push({
            event: gtagEvent
          });
        }

        setInvalidFields(null)
        setSent(true);
      })
      .catch((error) => {
        setInvalidFields(error.invalid_fields)
        setError(error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const ContactForm = cloneElement(children, {
    handler: formSubmitHandler,
    isLoading,
    isSent,
    hasError,
    invalidFields,
    showrooms,
    systems
  })

  return <div className="col-span-2">{url || (siteUrl && formId) ? ContactForm : <ErrorMessage />}</div>
}

export default Cf7FormWrapper