import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import { useEffect, useRef } from "react";

import BlSystemsSlide from "./BlSystemsSlide";
import Btn from "../../commons/btn";

const BlSystemsSlider = ({slides, scope, link}) => {
  const sliderRef = useRef();
  const controlsRef = useRef();
  const prevTitle = useRef();
  const nextTitle = useRef();

  const handleSlideTitleChange = (swiper) => {
    let prevTitleText;
    let nextTitleText;
    if (swiper.activeIndex > 0) {
      prevTitleText = swiper.slides[swiper.activeIndex - 1].dataset.slidetitle;
    } else {
      prevTitleText = swiper.slides[swiper.slides.length - 1].dataset.slidetitle;
    }
    if (swiper.activeIndex == (swiper.slides.length - 1)) {
      nextTitleText = swiper.slides[0].dataset.slidetitle;
    } else {
      nextTitleText = swiper.slides[swiper.activeIndex + 1].dataset.slidetitle;
    }

    if (prevTitle.current != undefined)
      prevTitle.current.innerHTML = prevTitleText;

    if (nextTitle.current != undefined)
      nextTitle.current.innerHTML = nextTitleText;
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      let tl = gsap.timeline({scrollTrigger: {trigger: scope.current, start: 'top 50%'}})
      tl.from(sliderRef.current, {y: 20, opacity: 0})
      tl.from(controlsRef.current,  {opacity: 0, delay: .5})
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <>
      <div className="
        col-span-2 sm:col-span-10 sm:col-start-2
        md:col-span-12 md:col-start-1
      " ref={sliderRef}>
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          navigation={{
            prevEl: '.block-hp-blsystems .prev',
            nextEl: '.block-hp-blsystems .next'
          }}
          pagination={{
            el: '.block-hp-blsystems .systems-slider-pag',
            type: 'bullets',
          }}
          breakpoints={{
            500: {
              slidesPerView: 1.2,
              spaceBetween: 20,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: true,
            },
          }}
          onAfterInit={handleSlideTitleChange}
          onSlideChange={handleSlideTitleChange}
        >
          {slides?.map(slide => (
            <SwiperSlide
              key={v4()}
              data-slidetitle={slide.title}
            >
              <BlSystemsSlide slide={slide} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="
        col-span-2 mt-30
        col-span-2 xs:col-span-10 xs:col-start-2
        sm:col-span-8 sm:col-start-3
        md:col-span-12 md:col-start-1
      " ref={controlsRef}>
        <div className="md:text-center md:mb-50 xl:mb-70">
          <Btn type='cta-2-2' title={link.title} uri={link.url} target={link.target} classes={'md:inline-flex'} />
        </div>
        <div className="
          systems-slider-nav flex items-center justify-between mt-30 mb-25
          md:my-0 md:absolute md:w-[90%] md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2 md:z-20
          lg:w-[82.5%]
        " >
          <div className="prev flex flex-col text-white items-end group md:hover:cursor-pointer xl:flex-row xl:items-center">
            <span className="icon icon-big-arrow-left xl:mr-15 transition-colors group-hover:text-action"></span>
            <span ref={prevTitle} className="slide-title ft-12 leading-[267%] tracking-[.3em] uppercase transition-colors group-hover:text-action"></span>
          </div>
          <div className="next flex flex-col text-white group md:hover:cursor-pointer xl:flex-row xl:items-center">
            <span ref={nextTitle} className="slide-title xl:mr-15 ft-12 leading-[267%] tracking-[.3em] uppercase transition-colors group-hover:text-action"></span>
            <span className="icon icon-big-arrow-right transition-colors group-hover:text-action"></span>
          </div>
        </div>
        <div className="systems-slider-pag"></div>
      </div>
    </>
  )
}

export default BlSystemsSlider