import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"

import InfiniteCard from "./InfiniteCard"

const InfiniteCardMobile = ({ cards }) => {
  return (
    <Swiper
      className="!overflow-visible"
      modules={[Autoplay]}
      slidesPerView={1.2}
      spaceBetween={20}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }}
      breakpoints={{
        500: {
          slidesPerView: 1.5
        },
        640: {
          slidesPerView: 2.1
        },
        768: {
          slidesPerView: 2.5
        },
        900: {
          slidesPerView: 2.25
        }
      }}
    >
      {cards?.map((item, index) => (
        <SwiperSlide key={v4()}>
          <InfiniteCard item={item} index={index} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default InfiniteCardMobile