import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef, useState } from "react";
import Image from "next/image";

import Heading from "../commons/Heading";
import Btn from "../commons/btn";
import { useRefsContext } from "../../../contexts";

const BlockVideo = ({ blockInfo }) => {
  const { index, icon, title, video, backgroundEffects } = blockInfo;

  const videoTag = useRef(null);
  const iframeTag = useRef(null);
  const [videoStatus, setVideoStatus] = useState('not-in-play');

  const handlePlayVideo = () => {
    if (videoTag.current != null) {
      videoTag.current.play();
      videoTag.current.setAttribute('controls', true);
    }
    if (iframeTag.current != undefined) {
      iframeTag.current.src = video?.external + '?autoplay=1';
    }
    setVideoStatus('in-play');
  }

  const scope = useRef();
  // background effects
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = scope.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-video block-index-${index} fb-block-padding-top lb-block-padding-bottom ${videoStatus}
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={scope}>
      <div className="w-full aspect-[9/12] xs:aspect-square sm:aspect-[21/9] relative bg-primary">
        <div className={`video-wrapper w-full h-full absolute z-10`}>
          <Image
            src={video?.poster?.sourceUrl}
            sizes="100vw"
            fill={true}
            alt={video?.poster?.altText}
            className="custom-poster object-cover transition-all"
          />
          {video?.source == 'fichier' ? (
            <video
              src={video?.file?.link}
              className="w-full h-full object-cover opacity-0 transition-all"
              playsInline
              ref={videoTag}
            />
          ) : (
            <iframe
              className="w-full h-full opacity-0 transition-all"
              src={video?.external + '?autoplay=0'}
              allow="autoplay"
              ref={iframeTag}
            />
          )}
        </div>
        <div
          className={`group container h-full flex items-center justify-center absolute left-1/2 -translate-x-1/2 z-40 transition-all hover:cursor-pointer`}
          onClick={handlePlayVideo}
        >
          <div className="absolute z-10 w-full bg-primary/30 backdrop-blur-[5.5px]" />
          <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal relative z-40">
            <div className="col-span-2 xs:col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3 text-center text-white">
              <Image
                src={icon?.sourceUrl}
                width={icon?.mediaDetails?.width}
                height={icon?.mediaDetails?.height}
                alt={title.replace(/<\/?[^>]+(>|$)/g, "")}
                className="mx-auto mb-20 3xl:mb-40"
              />
              <Heading tag="div" text={title} titleWChild={true} classes={'title-w-em mb-20 lg:mb-40 3xl:mb-60'} />
              <div><Btn type={'play-video'} classes={'inline-flex'} /></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockVideo