import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";

const CardWhyblAlt = ({ item, index }) => {

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context((self) => {
      if (window.innerWidth >= 1024 ) {
        let card = self.selector('.card');
        let value;
        if (index == 0) {
          value = +15;
        } else if (index == 1) {
          value = +5;
        } else if (index == 2) {
          value = -5;
        } else if (index == 3) {
          value = -15;
        }

        gsap.to(card, {
          yPercent: value,
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 80%',
            end: 'top top',
            scrub: 1,
          }
        })
      }
    }, [scope]);

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <div className={`
      col-span-2 mb-10 last:mb-0
      xs:col-span-10 xs:col-start-2
      sm:col-span-6 lg:col-span-3 lg:mb-0
    `} ref={scope}>
      <div className={`card h-full
        flex flex-col items-center px-15 py-50
        bg-dark-linear-gradient backdrop-blur-[6px] rounded-[20px]
      `}>
        <Image
          src={item?.image?.sourceUrl}
          width={item?.image?.mediaDetails?.width}
          height={item?.image?.mediaDetails?.height}
          alt={item?.text}
          className="mb-40"
        />
        <span className="
          text-[20px] 3xl:text-[22.5px] 4xl:text-[25px] 5xl:text-[28px] 6xl:text-[33px]
          leading-[150%] text-white font-extralight text-center">{item?.text}</span>
      </div>
    </div>
  )
}

export default CardWhyblAlt