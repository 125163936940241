import { gql } from "@apollo/client";
import MediaFragment from "../fragments/media";

export const GET_LISTING_TESTIMONIES = gql`
  query GetSliderTestimonies($after: String) {
    testimonies(
      first: 6
      after: $after
    ) {
      nodes {
        acfTestimonies {
          intelocutor {
            firstname
            lastname
            job
          }
          quote
          media {
            featuredImage {
              ...MediaFragment
            }
          }
        }
        uri
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${MediaFragment}
`;