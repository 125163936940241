import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { gsap } from "gsap";

import { useEffect, useRef } from "react";

import Image from "next/image";

import Breadcrumbs from "../commons/Breadcrumb";
import Heading from "../commons/Heading";
import MeaCarac from "./partials/MeaCarac";
import Btn from "../commons/btn";
import BuyButton from "../commons/BuyButton";

const BlockHeaderArmchair = ({ blockInfo }) => {
  const { index, title, armchairTagLine, meaCaracs, buyButton, breadcrumbs, productFeaturedImage, titleFont } = blockInfo;

  const scope = useRef();
  const imageRef = useRef();
  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(imageRef.current, {opacity: 1, scale: 1, duration: .5, delay: .5});
    }, [scope]);

    return () => {
      ctx.revert();
    }
  }, [])


   return (
    <section className={`
      block-header-armchair block-index-${index} relative z-10 common-block-padding-bottom
    `} ref={scope}>
      <div className="container hfpt">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-3 absolute top-20 lg:top-135 5xl:top-139 6xl:top-146
          ">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <div className="
            col-span-2 xs:col-span-12 mt-50 lg:mt-0 lg:col-span-10 lg:col-start-2
          ">
            <Heading tag="h1" text={title} classes={`mt-30 lg:mt-0 text-center text-white font-${titleFont}`} animate={false} />
            {!isEmpty(armchairTagLine) && (
              <div className="text-white text-center
                slide-desc text-[20px] lg:text-[16px] 3xl:text-[22.5px] 4xl:text-[25px] 5xl:text-[28px] 6xl:text-[33px] leading-[150%]
              ">{ armchairTagLine }</div>
            )}
            <div className="relative py-100 xs:py-50 3xl:py-100">
              <Image
                src={productFeaturedImage?.sourceUrl}
                width={productFeaturedImage?.mediaDetails?.width}
                height={productFeaturedImage?.mediaDetails?.height}
                priority={true}
                alt={title}
                className="product-image w-full max-w-[560px] xs:w-[80%] xs:mx-auto opacity-0 scale-[0.98]"
                ref={imageRef}
              />
              {Object.keys(meaCaracs).map((key, index) => (
                <MeaCarac key={v4()} index={index+2} carac={meaCaracs[key]} position={key} />
              ))}
            </div>
          </div>

          {isEmpty(buyButton) ? (
            <div className="
              col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-6 md:col-start-4
              xl:col-span-4 xl:col-start-5 pt-50 text-center
            ">
              <Btn type="normal-test" title={"Tester l'expérience"} classes={'mb-20'} />
              <Btn type="cta-5" title={'Voir les caractéristiques'} scrollTo={"#caracs"}  />
            </div>
          ) : (
            <div className="
              col-span-2 pt-50 text-center xs:col-span-10 xs:col-start-2
              sm:col-span-12
            ">
              <BuyButton code={buyButton} className="mb-20" />
              <Btn type="cta-5" title={'Voir les caractéristiques'} scrollTo={"#caracs"} />
            </div>
          )}

        </div>
      </div>
    </section>
  )
}

export default BlockHeaderArmchair