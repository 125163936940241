import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"

import ArchiveProgrammCard from "./ArchiveProgrammCard";

const SliderProgramms = ({ programms }) => {

  return (
    <div className="programms-slider">
      <Swiper
      className="!overflow-visible"
        modules={[Autoplay]}
        autoplay={{
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }}
        slidesPerView={1.25}
        spaceBetween={20}
        breakpoints={{
          500: {
            slidesPerView: 1.75
          },
          640: {
            slidesPerView: 2.25
          },
          768: {
            slidesPerView: 2.75
          },
          1024: {
            slidesPerView: 3.25
          },
          1280: {
            slidesPerView: 3.75
          },
          1536: {
            slidesPerView: 4.25
          },
          1920: {
            slidesPerView: 5.25
          },
          2220: {
            slidesPerView: 6.25
          }
        }}
      >
        {programms.map(programm => (
          <SwiperSlide key={v4()} className="!h-auto">
            <ArchiveProgrammCard programm={programm} classes='h-full' />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SliderProgramms