import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"

import AccessoryCard from "./AccessoryCard";

const SliderAccessories = ({ products }) => {

  return (
    <div className="armchairs-slider">
      <Swiper
        className="!overflow-visible"
        modules={[Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }}
        slidesPerView={1.1}
        spaceBetween={20}
        breakpoints={{
          500: {
            slidesPerView: 1.75
          },
          640: {
            slidesPerView: 2.25
          },
          768: {
            slidesPerView: 2.5
          },
          1024: {
            slidesPerView: 3.25
          },
          1280: {
            slidesPerView: 4
          }
        }}
      >
        {products.map((accessory, index) => (
          <SwiperSlide key={v4()} className="!h-auto">
            <AccessoryCard index={index} product={accessory} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SliderAccessories