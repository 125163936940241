import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";

import CategoryTag from "./CategoryTag";
import Heading from "../../commons/Heading";
import Link from "next/link";
import Btn from "../../commons/btn";

const BlogPost = ({ post, index }) => {

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (index >= 2) {
      let ctx = gsap.context(() => {
        gsap.from(scope.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0,
          delay: 0.1*(index%2)
        })
      }, [scope])

      return () => {
        ctx.revert();
      }
    }
  }, [])

  return (
    <Link href={post.uri} className="col-span-2 xs:col-span-12 md:col-span-6 lg:col-span-5 odd:lg:col-start-2" ref={scope}>
      <div className="relative w-full aspect-[533/308] rounded-[10px] overflow-hidden">
        <Image
          src={post?.featuredImage?.node?.sourceUrl}
          sizes={post?.featuredImage?.node?.sizes}
          fill={true}
          className="object-cover"
          alt={post.title}
        />
        {!isEmpty(post.tags) && (
          <div className="absolute left-10 bottom-10 flex items-center">
            {post.tags?.nodes?.map(tag => (
              <CategoryTag key={v4()} category={tag} />
            ))}
          </div>
        )}
      </div>
      <div className="mt-20">
        <Heading tag="h2" text={post.title} classes={'h5 mb-20 text-white'} animate={false} />
        <Btn type={'cta-light-2'} title={'Lire l\'article'} textClasses={'text-white'} iconClasses={'text-white'}  />
      </div>
    </Link>
  )
}

export default BlogPost