import { isEmpty } from "lodash";

import Image from "next/image";
import Link from "next/link";

import Wysiwyg from "../../../commons/Wysiwyg";

const ShowroomPopup = ({item, index}) => {

  return (
    <div className={`marker-popup showroom-popup text-white`} data-index={'index-'+index}>
      <div className="mp-title">{item.title}</div>
      {!isEmpty(item.image) && (
        <div className="img-wrapper w-full aspect-[427/145] relative">
          <Image
            src={item.image?.sourceUrl}
            sizes={item.image.sizes}
            fill={true}
            className="w-full h-full object-cover"
            alt={item.title}
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-mobile lg:gap-normal">
        <div className="col-span-1">
          <div className="localisation flex items-center gap-[8px]">
            <span className="icon icon-marker text-action"></span>
            <span className="city">{item.city}</span>
          </div>
          <Wysiwyg content={item.address} classes={'address font-extralight'} />
          <p className="font-medium">
            <Link href={'tel:'+item.phone}>Tel : {item.phone}</Link><br />
            <Link href={'mailto:'+item.email}>{item.email}</Link>
          </p>
        </div>
        <div className="col-span-1">
          <div className="schedules">
            <span className="schedules-title !font-extralight">Horaires :</span>
          </div>
          <Wysiwyg content={item.schedules} classes={'font-extralight'} />
        </div>
      </div>
    </div>
  )
}

export default ShowroomPopup