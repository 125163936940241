import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import ArmchairCard from "./partials/ArmchairCard";
import Heading from "../commons/Heading";
import Btn from "../commons/btn";

const BlockArmchairsMea = ({ blockInfo }) => {
  const { index, title, products, displayBtns, backgroundEffects } = blockInfo;
  if (isEmpty(products)) { return null; }


  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  return (
    <section className={`
      block-products-mea block-index-${index} common-block-padding
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div className="container">
        <Heading
          tag="div" text={title} titleWChild={true} animate={false}
          classes="unexpected mb-30 2xl:mb-35 4xl:mb-40 5xl:mb-50 6xl:mb-60 text-white title-w-em text-center md:text-left"
        />


        <div className="grid grid-cols-mobile gap-x-mobile gap-y-mobile xs:grid-cols-normal lg:gap-normal xl:gap-y-0">
          {products?.map((product, index) => (
            <ArmchairCard animate={true} key={v4()} index={index} product={product} classes={'col-span-2 xs:col-span-10 xs:col-start-2 md:col-span-6 xl:col-span-3'} />
          ))}
        </div>

        {displayBtns == true && (
          <div className="flex flex-col md:flex-row gap-25 items-center md:justify-center mt-40 lg:mt-60 xl:mt-80 5xl:mt-100 6xl:mt-120
          ">
            <Btn type="normal-test" classes={'inline-flex'} />
            <Btn type="shop-outside-header" title={'Accéder à la boutique'}  />
          </div>
        )}
      </div>

    </section>
  )
}

export default BlockArmchairsMea