const CategoryTag = ({ category }) => {

  return (
    <div className={`flex items-center bg-white/70 rounded-[35px] backdrop-blur-[1.5px] px-10 py-6 mr-5`}>
      <span className="icon icon-pin text-[#292D32] opacity-30 mr-5"></span>
      <span className="ft-9 leading-[144%] text-[#7F8387]">{ category.name }</span>
    </div>
  )
}

export default CategoryTag