import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import Breadcrumbs from "../commons/Breadcrumb";
import Heading from "../commons/Heading";

const BlockCommonHeader = ({ blockInfo }) => {
  const { index, title, intro, breadcrumbs, backgroundEffects } = blockInfo;

  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

   return (
    <section className={`
      block-common-header block-index-${index} relative z-10 h-screen flex items-center justify-center pb-25 lg:pb-40
      lg:h-auto
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div className="container hfpt lg:!pt-0">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-3 absolute top-100 lg:relative lg:top-0 lg:pt-20 xl:col-span-2 xl:pt-30 5xl:pt-40
          ">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <div className="
            col-span-2 xs:col-span-12 mt-50 text-center text-white
            lg:col-span-9 lg:col-start-4 lg:text-left lg:mt-0 xl:col-span-8 xl:col-start-3
          ">
            <Heading tag="div" text={title} titleWChild={true} classes="title-w-em mb-50 lg:mb-15" animate={false} />
            <Heading tag="div" text={intro} classes="intro" animate={false} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockCommonHeader