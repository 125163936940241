const MediaFragment = `
  fragment MediaFragment on MediaItem {
    id
    title
    altText
    description
    sourceUrl
    sizes
    link
    guid
    mediaDetails {
      height
      width
    }
  }
`;

export default MediaFragment;