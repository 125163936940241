import { isEmpty } from "lodash";

import Image from "next/image";

import Btn from "../commons/btn";
import Wysiwyg from "../commons/Wysiwyg";
import Heading from "../commons/Heading";

const BlockPageAwards = ({ blockInfo }) => {
  const { index, title, text, link, images } = blockInfo;

  return (
    <section className={`
      block-page-awards block-index-${index} relative z-10 py-50 lg:py-80
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal lg:items-center">
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-5
            lg:col-span-4 lg:col-start-3
            text-white
          ">
            <Heading tag="div" text={title} classes="title-w-em" titleWChild={true} />
            <Wysiwyg content={text} classes={'mt-40'} />
            {!isEmpty(link) && (
              <Btn
                type="cta-2-2"
                title={link.title}
                uri={link.url}
                target={link.target}
                classes={`mt-40 lg:mt-80`}
              />
            )}
          </div>
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-6 md:col-start-7
            lg:col-span-5 lg:col-start-8 xl:col-span-4 xl:col-start-8
            mt-30
          ">
            <div className="flex">
              <div className="flex-[0_0_53%] flex flex-col justify-center">
                {!isEmpty(images.image1) && (
                  <Image
                    src={images?.image1?.sourceUrl}
                    width={images?.image1?.mediaDetails?.width}
                    height={images?.image1?.mediaDetails?.height}
                    alt={title}
                    className="w-full mb-20"
                  />
                )}
                {!isEmpty(images.image2) && (
                  <Image
                    src={images?.image2?.sourceUrl}
                    width={images?.image2?.mediaDetails?.width}
                    height={images?.image2?.mediaDetails?.height}
                    alt={title}
                    className="w-full"
                  />
                )}
              </div>
              <div className="flex-[0_0_45%]">
              {!isEmpty(images.image3) && (
                  <Image
                    src={images?.image3?.sourceUrl}
                    width={images?.image3?.mediaDetails?.width}
                    height={images?.image3?.mediaDetails?.height}
                    alt={title}
                    className="w-full"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPageAwards