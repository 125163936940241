import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image"

const TeamCard = ({ index, member }) => {
  if (member == null) { return; }

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*index
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])


  return (
    <div className="w-full relative aspect-[250/300] rounded-[10px] overflow-hidden opacity-100" ref={scope}>
      <Image
        src={member?.featuredImage?.node?.sourceUrl}
        sizes={member?.featuredImage?.node?.sizes}
        alt={member.title}
        fill={true}
        className="object-cover"
      />
    </div>
  )
}

export default TeamCard