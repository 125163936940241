import { isEmpty } from "lodash";
import { v4 } from "uuid";

// GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from 'react';
import Image from "next/image";

import Heading from "../commons/Heading";
import Btn from "../commons/btn";

const BlockHpForWho = ({ blockInfo }) => {
  const { index, suptitle, title, image, linkList } = blockInfo;

  const scope = useRef();
  const imgRef = useRef();
  const linkRefs = useRef([]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(imgRef.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 50%'
        },
        opacity: 0,
        x: -20,
        duration: .5
      })

      gsap.from(linkRefs.current, {
        scrollTrigger: {
          trigger: linkRefs.current[0],
          start: 'top 75%'
        },
        y: 20,
        opacity: 0,
        stagger: 0.1
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-hp-for-who block-index-${index} py-50 relative z-10 overflow-hidden
    `} ref={scope} >
      <div className="hidden md:block absolute z-1 top-50 left-0 w-[50vw] 3xl:w-[calc(50vw_-_20px)] aspect-[742/540]" ref={imgRef}>
        <Image src={image?.sourceUrl} sizes={image?.sizes} fill={true} className="object-cover" alt={title} />
      </div>
      <div className="container md:relative md:z-10">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="
            col-span-2 mb-30
            xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3
            md:col-span-3 md:col-start-7 md:pt-125
            3xl:pt-160 4xl:pt-[210px] 5xl:pt-[270px]
          ">
            <Heading
              tag="div" text={suptitle}
              classes="suptitle text-center md:text-start text-white text-shadow-[0_0_3px_#0B212A]"
            />
          </div>
          <div className="
            col-span-2 mb-50
            xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3
            md:col-span-10 md:col-start-3 md:mb-70 xl:mb-110 3xl:mb-130
          ">
            <Heading
              tag="div" text={title} titleWChild={true}
              classes="title text-white font-light text-center md:text-start"
            />
          </div>
          {!isEmpty(linkList) && linkList.map((item, index) => (
            <div
              key={v4()}
              className="col-span-2 mb-25 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-4"
              ref={(el) => (linkRefs.current[index] = el)}
            >
              <Btn
                type="link-button"
                title={item?.link?.title}
                uri={item?.link?.url}
                target={item?.link?.target}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default BlockHpForWho