import { isEmpty } from "lodash";
import { v4 } from "uuid";

import Image from "next/image";

import CategoryTag from "./partials/CategoryTag";
import Wysiwyg from "../commons/Wysiwyg";
import SocialSharing from "../commons/SocialSharing";

const BlockPostIntroduction = ({ blockInfo }) => {
  const { index, postFeaturedImage, postTags, content } = blockInfo;

  return (
    <section className={`
      block-post-introduction block-index-${index} common-block-padding
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-10 md:col-start-2 lg:col-span-6 lg:col-start-1">
            <div className="aspect-[644/372] w-full relative mb-10 rounded-[10px] overflow-hidden">
              <Image
                src={postFeaturedImage?.node?.sourceUrl}
                sizes={postFeaturedImage?.node?.sizes}
                fill={true}
                className="object-cover"
                alt={postFeaturedImage?.node?.altText}
              />
              {!isEmpty(postTags) && (
                <div className="absolute left-10 bottom-10 flex items-center">
                  {postTags?.nodes?.map(tag => (
                    <CategoryTag key={v4()} category={tag} />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-10 md:col-start-2 lg:col-span-5 lg:col-start-8 xl:col-span-4 xl:col-start-8 mt-20 lg:mt-0">
            <Wysiwyg content={content} classes={'text-white'}  />
            <SocialSharing style={'alt'} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPostIntroduction