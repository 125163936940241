import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image"
import Link from "next/link"

import Btn from "../../commons/btn"

const TestimonyCard = ({index, testimony}) => {
  if (testimony == null) { return }
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*index
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])


  return (
    <Link
      title={`${testimony.acfTestimonies.intelocutor.firstname} - ${testimony.acfTestimonies.intelocutor.job}`}
      href={testimony.uri}
      className={`group flex flex-col
        col-span-2 block border border-primary rounded-[15px] backdrop-filter-[6px]
        bg-white text-primary px-30 py-20 lg:px-45 lg:py-30
        xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-6
      `}
      ref={scope}
    >
      <div className="w-full aspect-[564/200] relative rounded-[10px] overflow-hidden mb-20">
        <Image
          src={testimony?.acfTestimonies?.media?.featuredImage?.sourceUrl}
          sizes={testimony?.acfTestimonies?.media?.featuredImage?.sizes}
          fill={true}
          alt={testimony.acfTestimonies.intelocutor.firstname+ ' ' +testimony.acfTestimonies.intelocutor.lastname}
        />
      </div>
      <div className="relative flex flex-col justify-between flex-1">
        <span className="absolute top-0 left-0 -translate-x-1/4 -translate-y-1/3 icon icon-quote opacity-10 text-[60px]"></span>
        <h5 className="mb-25">{testimony.acfTestimonies.quote}</h5>
        <div className="flex items-center justify-between">
          <div className="ft-9-to-14 leading-[157%] font-light">— {testimony.acfTestimonies.intelocutor.firstname} {testimony.acfTestimonies.intelocutor.lastname}, {testimony.acfTestimonies.intelocutor.job}</div>
          <Btn
            type={'cta-2-2-templateWhite'}
            title={'En savoir plus'}
            classes={'!px-15 !py-10 xl:!px-40 xl:!py-15'}
            textClasses={'hidden lg:block'}
          />
        </div>
      </div>
    </Link>
  )
}

export default TestimonyCard