import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"

import ProgrammCard from "../../commons/ProgrammCard"

const HpProgrammLine = ({ programms, type ='normal' }) => {
  return (
    <div className="programm-line">
      <Swiper
        modules={[Autoplay]}
        slidesPerView={'auto'}
        spaceBetween={10}
        breakpoints={{
          1280: {
            spaceBetween: 20
          }
        }}
        autoplay={{
          delay: 2000,
          reverseDirection: type == 'normal' ? false : true
        }}
        loop={true}
        speed= {30000}
        allowTouchMove={false}
        className="!overflow-visible"
      >
        {programms.map(programm => (
          <SwiperSlide key={v4()}>
            <ProgrammCard programm={programm} loading="lazy" />
          </SwiperSlide>
        ))}
        {programms.map(programm => (
          <SwiperSlide key={v4()}>
            <ProgrammCard programm={programm} loading="lazy" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default HpProgrammLine