import { isEmpty } from "lodash";

import TeamSlider from "./partials/TeamSlider";
import Btn from "../commons/btn";
import Heading from "../commons/Heading";

const BlockPageTeam = ({ blockInfo }) => {
  const { index, title, text, links, teamMembers }  = blockInfo;
  if (isEmpty(teamMembers?.nodes)) { return null;}

  return (
    <section className={`
      block-page-team block-index-${index} relative z-10 pt-25 lg:pt-40 pb-50 lg:pb-80
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3
            text-white lg:col-span-8 lg:col-start-3 2xl:col-span-6 2xl:col-start-3
          ">
            <Heading tag="div" text={title} classes="title-w-em" titleWChild={true} />
            <Heading tag="h3" text={text} classes={"h5 leading-[115%]"} />
          </div>
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-12
            pt-45 pb-30 lg:pt-90 lg:pb-60
          ">
            <TeamSlider teamMembers={teamMembers?.nodes} />
          </div>
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-12
            flex flex-col md:flex-row gap-20 justify-center
          ">
            {!isEmpty(links.link1) && (
              <Btn
                type="cta-2-2"
                title={links.link1.title}
                uri={links.link1.url}
                target={links.link1.target}
              />
            )}
            {!isEmpty(links.link2) && (
              <Btn
                type="contact-no-fun-style"
                title={links.link2.title}
                uri={links.link2.url}
                target={links.link2.target}
                classes={'lg:inline-flex'}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPageTeam