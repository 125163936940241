import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";

import Wysiwyg from "../commons/Wysiwyg";
import Heading from "../commons/Heading";

const BlockCommonImgTxt = ({ blockInfo }) => {
  const { index, suptitle, title, content, image } = blockInfo;
  const scope = useRef();
  const leftCol = useRef();
  const rightCol = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      if (window.innerWidth < 1024) {
        gsap.from([leftCol.current, rightCol.current], {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0
        })
      }
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-common-image-text block-index-${index} relative z-10 py-25 lg:py-40
    `} ref={scope}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal lg:items-center">
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-5 lg:col-span-5 lg:col-start-2
            mb-30
          " ref={leftCol}>
            <Image
              src={image?.sourceUrl}
              width={image?.mediaDetails?.width}
              height={image?.mediaDetails?.height}
              alt={title}
              className="w-full"
            />
          </div>
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-5 md:col-start-7 lg:col-span-4 lg:col-start-8
            text-white
          " ref={rightCol}>
            <Heading tag="div" text={suptitle} classes="suptitle" animate={false} />
            <Heading tag="div" text={title} classes="title-w-em" titleWChild={true} animate={false} />
            <Wysiwyg content={content} classes={'mt-40'} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockCommonImgTxt