import Link from "next/link"
import Wysiwyg from "../../../commons/Wysiwyg"

const ResellerPopup = ({item, index}) => {
  return (
    <div className={`marker-popup reseller-popup text-white` } data-index={'index-'+index}>
      <div className="mp-title">{item.title}</div>
      <div className="mp-jobtitle">{item.jobtitle}</div>
      <div className="localisation flex items-center gap-[8px]">
        <span className="icon icon-marker text-action"></span>
        <span className="city">{item.city}</span>
      </div>
      <Wysiwyg content={item.address} classes={'address font-extralight'} />
      <p className="font-medium">
        <Link href={'tel:'+item.phone}>Tel : {item.phone}</Link><br />
        <Link href={'mailto:'+item.email}>{item.email}</Link>
      </p>
    </div>
  )
}

export default ResellerPopup