import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";

const CardWhybl = ({ item, index }) => {
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context((self) => {
      if (window.innerWidth >= 768 ) {
        let card = self.selector('.card');
        if (index != 1) {
          let value;
          if (index == 0) {
            value = +33;
          } else {
            value = -33;
          }

          gsap.to(card, {
            yPercent: value,
            scrollTrigger: {
              trigger: scope.current,
              start: 'top 80%',
              end: 'top top',
              scrub: 1,
            }
          })
        }
      }
    }, [scope]);

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <div className={`
      col-span-2 mb-10 last:mb-0
      xs:col-span-10 xs:col-start-2
      sm:col-span-8 sm:col-start-3
      md:col-span-4 md:first:-translate-y-50 md:last:translate-y-50 md:mb-0
      xl:first:col-span-3 xl:last:col-span-3 xl:first:col-start-2 xl:last:col-start-9
    `} ref={scope}>
      <div className={`card h-full
        flex flex-col items-center px-15 py-50
        bg-dark-linear-gradient backdrop-blur-[6px] rounded-[20px]
        xl:max-w-[291px] xl:mx-auto
        2xl:max-w-[299px] 3xl:max-w-[345px] 4xl:max-w-[385px] 5xl:max-w-[450px] 6xl:max-w-[525px]
      `}>
        <Image
          src={item?.image?.sourceUrl}
          width={item?.image?.mediaDetails?.width}
          height={item?.image?.mediaDetails?.height}
          alt={item?.text}
          className="mb-40"
        />
        <span className="
          text-[20px] 3xl:text-[22.5px] 4xl:text-[25px] 5xl:text-[28px] 6xl:text-[33px]
          leading-[150%] text-white font-extralight text-center">{item?.text}</span>
      </div>
    </div>
  )
}

export default CardWhybl