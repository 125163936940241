import { v4 } from "uuid";
import parse from 'html-react-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import Image from "next/image";

import { useEffect, useRef, useState} from "react";

import { useLayoutContext, useRefsContext } from "../../../contexts";

import Btn from "../commons/btn";
import Heading from "../commons/Heading";

const BlockHpCompanyBanner = ({ blockInfo }) => {
  const { reloadFlag } = useLayoutContext();
  const { index, suptitle, title, content, link, images, bckImages, backgroundEffects} = blockInfo;

  const desktopW = useRef();
  const mobileW = useRef();

  const scope = useRef();
  const contentRef = useRef();

  const [bgUrl, setBgUrl] = useState(bckImages?.mobile?.sourceUrl);

  const swaper = () => {
    let desktopInner = desktopW.current.innerHTML;
    let mobileInner = mobileW.current.innerHTML;

    if (window.innerWidth >= 768 && desktopInner == '' || window.innerWidth < 768 && mobileInner == '') {
      desktopW.current.innerHTML = mobileInner;
      mobileW.current.innerHTML = desktopInner;
    }
  }


  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(contentRef.current, {
        scrollTrigger: {
          trigger: contentRef.current,
          start: 'top 66%'
        },
        opacity: 0,
        duration: .5
      })
    }, [scope])

    swaper();
    window.addEventListener('resize', swaper, { passive: true});
    return () => {
      window.removeEventListener('resize', swaper);
      ctx.revert();
    }
  }, [])

  useEffect(() => {
    reloadFlag == 'mobile' ? setBgUrl(bckImages?.mobile?.sourceUrl) : setBgUrl(bckImages?.desktop?.sourceUrl)
  }, [reloadFlag])

  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  return (
    <section className={`
      block-hp-company-banner block-index-${index} relative z-20
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div ref={mobileW} className="md:hidden">
        <div className="
          img-wrapper relative w-full aspect-[375/392] xs:aspect-[21/9]
          md:aspect-[528/769] md:absolute lg:aspect-[unset] lg:h-full
        ">
          <picture className={`block w-full h-full`}>
            <source srcSet={images?.desktop?.sourceUrl} media="(min-width: 768px)"></source>
            <Image
              src={images?.mobile?.sourceUrl}
              sizes={images?.mobile?.sizes}
              fill={true}
              alt={title}
              className="object-cover"
            />
          </picture>
        </div>
      </div>

      <div className="main-wrapper" ref={scope} style={{backgroundImage: "url(" + bgUrl + ")"}}>
        <div className="
          container py-50 md:py-85 xl:pt-105 xl:pb-200 2xl:pt-133 2xl:pb-222
          3xl:pt-150 3xl:pb-[250px] 4xl:pt-166 4xl:pb-[277px] 5xl:pt-192 5xl:pb-[320px] 6xl:pt-[221px] 6xl:pb-[370px]
        ">
          <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
            <div className="col-span-2 xs:col-span-10 xs:col-start-2
              sm:col-span-8 sm:col-start-3 md:col-span-5 md:col-start-2 text-white">
              <Heading tag="div" text={suptitle} classes="suptitle" animate={true} />
              <Heading tag="h2" text={title} classes="h3 my-25 !leading-[105%]" animate={true} />
              <div ref={contentRef}>
                <div className="md:w-[80%]">{parse(content)}</div>
                <Btn
                  type={'cta-2-2'}
                  title={link?.title}
                  uri={link?.url}
                  target={link?.target}
                  classes={`mt-25`}
                />
              </div>
            </div>
            <div className="hidden md:block md:relative md:col-span-5 md:col-start-8" ref={desktopW}></div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default BlockHpCompanyBanner