import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Heading from "../commons/Heading";
import Wysiwyg from "../commons/Wysiwyg";

const BlockPostBanner = ({ blockInfo }) => {
  const {index, title, content, display2cols}  = blockInfo;
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section
      className={`block-post-banner block-index-${index} common-block-padding relative z-10 py-25 lg:py-40`}
      ref={scope}
    >
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="main-wrapper
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-12 md:col-start-1
            bg-dark-linear-gradient backdrop-blur-[6px] rounded-[20px] text-white
          ">
            <Heading tag="div" text={title} classes="pb-20 mb-20 md:pb-40 md:mb-40 border-b border-text" titleWChild={true} animate={false} />
            <Wysiwyg content={content} classes={`blog mb-30 md:mb-60 ${display2cols == true && 'md:columns-2'}`} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPostBanner