import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Image from "next/image"
import Link from "next/link";

import SampleAudio from "../../commons/SampleAudio";

const ArchiveProgrammCard = ({ index, programm, classes = '' }) => {
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*(index%4)
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  const handleClick = event => {
    if (event.target.classList.contains('icon')) {
      event.preventDefault();
    }
  }

  return (
    <Link
      href={programm?.uri}
      className={`
        programm-card archive-programm-card relative col-span-2 xs:col-span-6 md:col-span-4 xl:col-span-3
        flex flex-col items-center justify-between rounded-[10px] text-center
        ${classes}
      `}
      style={{ background: programm?.acfProgramms?.gradient }}
      onClick={handleClick}
      ref={scope}
    >
      <div className="hidden lg:block absolute top-20 right-20 z-10">
        <SampleAudio audio={programm?.acfProgramms?.audio} />
      </div>
      <Image
        src={programm?.featuredImage?.node?.sourceUrl}
        sizes={programm?.featuredImage?.node?.sizes}
        width={programm?.featuredImage?.node?.mediaDetails?.width}
        height={programm?.featuredImage?.node?.mediaDetails?.height}
        alt={programm?.title}
        className="mb-30 w-full h-auto"
      />
      <span
        className="programm-title flex items-center justify-center
          title font-light tracking-[.3em] uppercase text-white
      "><span>{programm?.title}</span></span>
    </Link>
  )
}

export default ArchiveProgrammCard;