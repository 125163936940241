import parse from 'html-react-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from 'react';

import Heading from "../commons/Heading";
import Btn from '../commons/btn';

const BlockTitleText = ({ blockInfo }) => {
  const {index, suptitle, titre, contenu, displayShopBtn} = blockInfo;

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      if (window.innerWidth < 1024) {
        gsap.from(scope.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0
        })
      } else {
        gsap.from(scope.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          x: 20,
          opacity: 0
        })
      }
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-title-text block-index-${index} common-block-padding
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:items-center lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-12 sm:col-span-10 sm:col-start-2 md:col-span-6 lg:col-span-5 lg:col-start-2">
            <Heading tag="div" text={suptitle} classes={'suptitle text-white'} />
            <Heading tag="div" text={titre} titleWChild={true} classes={'title-w-em text-white mb-30 md:mb-0'} />
          </div>
          <div className="col-span-2 xs:col-span-12 sm:col-span-10 sm:col-start-2 md:col-span-6 lg:col-span-4" ref={scope}>
            <div className='wysiwyg text-white mb-20 lg:mb-30 2xl:mb-45'>{parse(contenu)}</div>
            {displayShopBtn == true && ( <Btn type="shop-outside-header" title="Accéder à la boutique" /> )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockTitleText