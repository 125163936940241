import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"

import Link from "next/link";

const TestimoniesSlider = ({ testimonies}) => {
  if (isEmpty(testimonies)) { return null; }


  return (
    <div className="testimonies-slider">
      <div>
        {!isEmpty(testimonies) && (
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1.25}
            spaceBetween={10}
            centeredSlides={true}
            loop={true}
            autoplay={true}
            breakpoints={{
              768: {
                slidesPerView: 1.75,
                slidesPerGroup: 1,
                spaceBetween: 30,
                centeredSlides: false
              },
              1536: {
                slidesPerView: 1.5,
                slidesPerGroup: 1,
                spaceBetween: 30,
                centeredSlides: false
              }
            }}
            className="!overflow-visible"
          >
            {testimonies.map(testimony => (
              <SwiperSlide key={v4()}>
                <Link
                  title={`${testimony.acfTestimonies.intelocutor.firstname} - ${testimony.acfTestimonies.intelocutor.job}`}
                  href={testimony.guid}
                  className={'block border border-primary rounded-[15px] backdrop-filter-[6px] text-primary px-30 pt-45 pb-20 lg:px-45 lg:pt-75 lg:pb-30'}
                >
                  <span className="absolute top-20 left-15 lg:top-[15%] lg:left-30 icon icon-quote opacity-10 text-[60px]"></span>
                  <h5 className="mb-20 lg:mb-60">{testimony.acfTestimonies.quote}</h5>
                  <div className="ft-9-to-14 leading-[157%] font-light">— {testimony.acfTestimonies.intelocutor.firstname} {testimony.acfTestimonies.intelocutor.lastname}, {testimony.acfTestimonies.intelocutor.job}</div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

export default TestimoniesSlider