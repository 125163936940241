import { isEmpty } from "lodash";
import { v4 } from "uuid";

import AccessoryCard from "./partials/AccessoryCard";

const BlockListingAccessories = ({ blockInfo }) => {
  const { index, accessories } = blockInfo;
  if (isEmpty(accessories)) { return null; }

  return (
    <section className={`
      block-cat-listing-accessories block-index-${index} common-block-padding
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile gap-y-mobile xs:grid-cols-normal lg:gap-normal ">
          {accessories?.map((product, index) => (
            <div key={v4()} className="col-span-2 xs:col-span-8 xs:col-start-3 sm:col-span-6 lg:col-span-4 xl:col-span-3">
              <AccessoryCard  index={index} product={product} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default BlockListingAccessories