import Breadcrumbs from "../commons/Breadcrumb";
import Heading from "../commons/Heading";

const BlockHeaderBlog = ({ blockInfo }) => {
  const { index, title, breadcrumbs } = blockInfo;

   return (
    <section className={`
      block-common-header block-index-${index} relative z-10 h-screen flex items-center justify-center pb-25 lg:pb-40
      lg:h-auto
    `}>
      <div className="container hfpt">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-3 absolute top-100 lg:relative lg:top-0 lg:pt-20 xl:col-span-2 xl:pt-30 5xl:pt-40
          ">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <div className="
            col-span-2 xs:col-span-12 mt-50 text-center text-white
            lg:col-span-9 lg:col-start-4 lg:text-left lg:mt-0 xl:col-span-10 xl:col-start-3
          ">
            <Heading tag="h1" text={title} classes="mb-50 lg:mb-15" animate={false} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockHeaderBlog