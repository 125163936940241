import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Image from "next/image"

const CommercialAgentsSlide = ({index, item}) => {
  if (item == null) { return }

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*index
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <div
      className="block w-full aspect-[421/469] relative rounded-[10px] overflow-hidden"
      ref={scope}
    >
      <Image
        src={item?.image?.sourceUrl}
        sizes={item?.image?.sizes}
        fill={true}
        className="object-cover"
        alt={item.firstname + item.lastname}
      />
      <div className="
        w-full absolute bottom-0 left-0 px-25 pt-50 pb-30 bg-team-linear-gradient text-white
      ">
        <h5>{item.firstname} {item.lastname}</h5>
        <div className="ft-20 font-light italic leading-[175%]">{item.jobtitle} + {item.localisation}</div>
      </div>
    </div>
  )
}

export default CommercialAgentsSlide