import { isEmpty } from "lodash";
import parse from 'html-react-parser';

import { useEffect } from "react";
import { useLayoutContext } from "../../../contexts";

import Image from "next/image";

import Breadcrumbs from "../commons/Breadcrumb";
import Heading from "../commons/Heading";
import Btn from "../commons/btn";
import Wysiwyg from "../commons/Wysiwyg";
import BuyButton from "../commons/BuyButton";
;
const BlockAccessoriesHeader = ({ blockInfo }) => {
  const {index, backgroundEffects, title, presentation, breadcrumbs, productFeaturedImage, systemTagLine, buyButton } = blockInfo;
  const { reloadFlag } = useLayoutContext();

  useEffect(() => {}, [reloadFlag])

  return (
    <section className={`
      block-header-programm block-index-${index} relative z-10 lb-block-padding-bottom
      ${backgroundEffects?.activate && 'background-effects'}
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)}>
      <div className="container common-block-padding-top">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-12 lg:col-span-3 xl:col-span-2 mb-40 pt-20 xl:pt-40">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <div className={`col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 lg:col-span-5 2xl:col-span-4 ${!isEmpty(systemTagLine) && 'w-tagline'}`}>
            <Heading tag="h1" text={title} classes={"text-white text-center lg:text-left"} animate={false} />
            {!isEmpty(systemTagLine) ? (<p className="text-white tagline">{parse(systemTagLine)}</p>) : null}
            {reloadFlag == 'mobile' && !isEmpty(productFeaturedImage) && (
              <Image
                src={productFeaturedImage?.sourceUrl}
                width={productFeaturedImage?.mediaDetails?.width}
                height={productFeaturedImage?.mediaDetails?.height}
                alt={title}
                className="w-full h-auto rounded-[10px] mb-30"
              />
            )}
            <Wysiwyg content={presentation} classes={'mb-30 text-white'} />
            <div className="text-center lg:text-left">
              {reloadFlag == 'mobile' && <BuyButton code={buyButton} />}
              <Btn type='shop-outside-header' title={"Accéder à la boutique"} classes={'lg:inline-flex'} />
            </div>
          </div>

          {reloadFlag == 'desktop' && (
            <div className="lg:col-span-4 xl:col-span-5 xl:col-start-8">
              {!isEmpty(productFeaturedImage) && (
                <Image
                  src={productFeaturedImage?.sourceUrl}
                  width={productFeaturedImage?.mediaDetails?.width}
                  height={productFeaturedImage?.mediaDetails?.height}
                  alt={title}
                  className="w-full h-auto rounded-[10px]"
                />
              )}
              <BuyButton code={buyButton} className="mt-30" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default BlockAccessoriesHeader