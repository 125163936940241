import { v4 } from "uuid";

import ComparatorArmchairCard from "./ComparatorArmchairCard";
import ComparatorPart from "./ComparatorPart";

const Comparator = ({ comparatorDatas }) => {
  return (
    <div className="comparator pt-50">
      <div className="comparator-header common-block-padding-bottom">
        <div className="flex gap-x-mobile lg:gap-x-normal">
          {comparatorDatas?.map((product, index) => (<ComparatorArmchairCard key={v4()} product={product} index={index} />))}
        </div>
      </div>
      {comparatorDatas?.[0]?.acfArmchairs?.comparator?.map((item, index) => (
        <ComparatorPart key={item?.title + index} index={index} title={item?.title} products={comparatorDatas} />
      ))}
    </div>
  )
}

export default Comparator