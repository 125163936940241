import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import "swiper/css/autoplay"

import ArmchairCard from "./ArmchairCard";

const SliderArmchairs = ({ armchairs }) => {

  return (
    <div className="armchairs-slider">
      <Swiper
      className="!overflow-visible"
        modules={[Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }}
        slidesPerView={1.1}
        spaceBetween={20}
        breakpoints={{
          500: {
            slidesPerView: 1.25
          },
          640: {
            slidesPerView: 1.75
          },
          768: {
            slidesPerView: 2.25
          },
          1024: {
            slidesPerView: 3
          }
        }}
      >
        {armchairs.map((armchair, index) => (
          <SwiperSlide key={v4()} className="!h-auto">
            <ArmchairCard index={index} product={armchair} hideExcerpt={true}  />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SliderArmchairs