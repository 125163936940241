import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";

import Heading from "../commons/Heading";
import Wysiwyg from "../commons/Wysiwyg";

const BlockBannerWTittle = ({ blockInfo }) => {
  const { index, title, image, suptitle, text } = blockInfo;

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-banner-w-title block-index-${index} fb-block-padding-top common-block-padding-bottom
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-12">
            <Heading tag="h3" text={title}
              classes={'text-center text-white mb-30 md:mb-45 lg:mb-60 2xl:mb-70 5xl:mb-90 6xl:mb-120'}
            />
          </div>
          <div className="col-span-2 xs:col-span-12 xl:col-span-10 xl:col-start-2 bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px]" ref={scope}>
            <div className="md:flex lg:w-4/5 lg:mx-auto
              p-20 md:p-40 2xl:py-60 5xl:py-80 6xl:py-100
            ">
              <Image
                src={image?.sourceUrl}
                width={image?.mediaDetails?.width}
                height={image?.mediaDetails?.height}
                alt={image?.altText}
                className="mr-50 hidden md:block"
              />
              <div className="">
                <div className="mb-20 md:mb-10 5xl:mb-20">
                  <Image
                    src={image?.sourceUrl}
                    width={image?.mediaDetails?.width}
                    height={image?.mediaDetails?.height}
                    alt={image?.altText}
                    className="mb-30 mr-30 md:hidden md:mb-0 md:mr-0"
                  />
                  <Heading tag="div" text={suptitle} classes={'suptitle !font-light text-white'} animate={false} />
                </div>
                <Wysiwyg content={text} classes={'text-white'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockBannerWTittle
