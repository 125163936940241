import LeftRelative from "./LeftRelative";
import LeftAbsolute from "./LeftAbsolute";

const ContentLeftImg = ({blockInfo}) => {
  const {configuration} = blockInfo;

  if(configuration.imgAbsolute == "relative") {
    return <LeftRelative blockInfo={blockInfo} />
  } else {
    return <LeftAbsolute blockInfo={blockInfo} />
  }
}

export default ContentLeftImg