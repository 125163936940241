import { useLayoutContext } from "../../../../contexts"

import { useEffect } from "react"
import TechnoMobile from "./ProductTechnologies/TechnoMobile";
import TechnoDesktop from "./ProductTechnologies/TechnoDesktop";

const ProductTechnologies = ({ technologies }) => {
  const { reloadFlag } = useLayoutContext();

  useEffect(() => {

  }, [reloadFlag])

  return (
    <div className="products-technologies">
      {reloadFlag == 'mobile' ? (
        <TechnoMobile technologies={technologies} />
      ) : (
        <TechnoDesktop technologies={technologies} />
      )}
    </div>
  )
}

export default ProductTechnologies