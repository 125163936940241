import { isEmpty } from "lodash";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Heading from "./Heading";
import Btn from "./btn";

const ComparatorBanner = ({ title, text, linkText }) => {
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <div className="
      comparator-banner col-span-12 xs:col-span-12 lg:col-span-10 lg:col-start-2 common-block-padding
      bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] overflow-hidden text-white text-center
    " ref={scope}>
      {!isEmpty(title) && ( <Heading tag='h4' text={title} animate={false} /> )}
      {!isEmpty(text) && ( <Heading tag='p' text={text} classes={'mb-30 3xl:mb-45 6xl:mb-60'} animate={false} /> )}
      {!isEmpty(linkText) && (
        <div className="px-[16px]">
          <Btn type='cta-2-2' title={linkText} uri='/nos-produits/nos-fauteuils/comparer/' />
        </div>
      )}
    </div>
  )
}

export default ComparatorBanner