import { isEmpty } from "lodash";
import { v4 } from "uuid";

import client from "../../utils/apollo/client";
import { GET_LISTING_TESTIMONIES } from "../../queries/components/get-listing-testimonies";

import { useState,  } from "react";

import TestimonyCard from "./partials/TestimonyCard";
import Btn from "../commons/btn";

const BlockListingTestimonies = ({ blockInfo }) => {
  const { index, testimoniesForListing } = blockInfo;
  if (isEmpty(testimoniesForListing?.nodes)) { return null; }

  const [testimonies, setTestimonies] = useState(testimoniesForListing.nodes);
  const [showLoadMore, setShowLoadMore] = useState(testimoniesForListing.pageInfo.hasNextPage);
  const [cursor, setCursor] = useState(testimoniesForListing.pageInfo.endCursor);

  const FetchLdTestimonies = async () => {
    const { data } = await client.query({
      query: GET_LISTING_TESTIMONIES,
      variables: {
        after: cursor
      }
    });

    setTestimonies(prevTestimonies => [...prevTestimonies, ...data.testimonies.nodes]);
    setShowLoadMore(data.testimonies.pageInfo.hasNextPage);
    setCursor(data.testimonies.pageInfo.endCursor);
  }


  const handleLoadMore = () => {
    FetchLdTestimonies();
  };

  return (
    <section className={`
      block-page-listing-testimonies block-index-${index} relative z-10 lg:py-80
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile gap-y-normal xs:grid-cols-normal lg:gap-x-normal">
          {!isEmpty(testimonies) && testimonies.map((testimony, index) => (
            <TestimonyCard key={v4()} testimony={testimony} index={index} />
          ))}
        </div>
        <div className="col-span-2 xs:col-span-12 text-center lg:mt-50 4xl:mt-100 ">
          <div className={`${!showLoadMore ? 'hidden' : 'inline-block'}`} onClick={handleLoadMore}>
            <Btn type='cta-2-2-templateWhite' title='Voir plus' iconClasses={'rotate-90'} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockListingTestimonies