import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Heading from "../../commons/Heading"
import Wysiwyg from "../../commons/Wysiwyg"

const ProgrammPresentation = ({item, index, length}) => {
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*(index%3)
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <div className={`
      col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 lg:col-span-4
      p-20 sm:p-40 lg:p-20 xl:p-40 bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px]
      ${length == 1 && 'lg:!col-span-12'}
      ${length == 2 && 'lg:!col-span-6'}
    `} ref={scope}>
      <Heading tag="h3" text={item.title} classes="h4 mb-20 text-white" animate={false} />
      <Wysiwyg content={item.content} classes={'text-white'} />
    </div>
  )
}

export default ProgrammPresentation