import parse from 'html-react-parser';
import Image from "next/image"

const BlSystemsSlide = ({ slide }) => {
  let excerpt = slide?.acfArmchairs?.excerpt;
  if (excerpt == null) {
    excerpt = slide?.acfSystems?.excerpt
  }


  return (
    <div className="bl-system-slide flex flex-col items-center text-center">
      <div className="img-wrapper lg:w-full">
        <Image
          src={slide?.featuredImage.node?.sourceUrl}
          width={slide?.featuredImage.node?.mediaDetails.width}
          height={slide?.featuredImage.node?.mediaDetails.height}
          alt={slide.title}
          className="lg:h-full lg:w-auto lg:mx-auto"
        />
      </div>
      <h3 className={`
        slide-title tracking-[.1em] md:tracking-[.3em] uppercase text-white font-thin text-shadow-[0_0_3px_#0B212A]
        mt-25 mb-15 font-${slide?.acfArmchairs?.titleFont}
      `}>{ slide.title }</h3>
      <div className="text-white
        slide-desc text-[20px] 3xl:text-[22.5px] 4xl:text-[25px] 5xl:text-[28px] 6xl:text-[33px] leading-[150%]
      ">{parse(slide?.acfArmchairs?.excerpt)}</div>
    </div>
  )
}

export default BlSystemsSlide