import Checkbox from "./commons/Checkbox";

const Field = ({data, handleFieldChange,theme}) => {

  const handleKeyDown = (e) => {
    let el = e.target
    let max = 135;
    if (e.key == 'Enter') {
      let height = el.offsetHeight;
      height = height + 27;
      if (height > max) {
        height = max;
      }
      el.style.height  = height + 'px';
    }
  }

  const handleFocus = (event) => {
    event.target.parentNode.classList.toggle('focus-in')
  }

  if(data.type == "textarea") {
    return (
      <div className={`${data.classes} mb-6 relative input-wrapper class-textarea pt-[30px] lg:pt[45px]`}>
        <textarea
          placeholder={data.placeholder}
          rows={1}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => handleFieldChange(data.name, e)}
          className={`block placeholder-opacity-100 outline-0 py-11 font-sans bg-transparent w-full text-[16px] 2xl:text-[18px] border-b-[1px] ${theme !== "dark" ? "border-white text-white" : "border-white text-white "}`}
          id={data.name}
          onFocus={handleFocus} onBlur={handleFocus}
        >
        </textarea>
        {data.invalid != false ? (<span className="pt-2 font-sans block text-red-500">{data.invalid}</span>) :  null}
      </div>
    )
  } else if (data.type == "checkbox") {
    return (
      <Checkbox
        data={data}
        handleFieldChange={handleFieldChange}
      />
    )
  } else {
    return (
      <div className={`${data.classes} mb-24 lg:mb-28`}>
        <div className='relative input-wrapper'>
          <label className={`absolute ${theme !== "dark" ? "text-black" : "text-white "} left-0 top-[50%] -translate-y-[50%] pointer-events-none select-none`}>
            <span className="text-[16px] 2xl:text-[18px]">{data.placeholder}</span>
            <span className='absolute right-0 top-0 block required translate-x-[110%] translate-y-[50%]  text-[10px] opacity-50 '>
             {data.required == false }
            </span>
          </label>
          <input
            onChange={(e) => handleFieldChange(data.name, e)}
            onFocus={handleFocus}
            onBlur={handleFocus}
            className={`placeholder-opacity-100 outline-0 py-11 font-sanstext-[16px] 2xl:text-[18px] bg-transparent border-b-[1px] ${theme !== "dark" ? "border-white text-white" : "border-white text-white "} w-full ${data.invalid ? "border-red-500" :  null}`}
            id={data.name}
            type={data.type == "phone" ? "tel" : data.type}
          />
        </div>
        {data.invalid != false ? (<span className="pt-2 font-sans block text-red-500">{data.invalid}</span>) :  null}
      </div>
    )
  }
}

export default Field