import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import Heading from "../commons/Heading";
import Btn from "../commons/btn";
import HpProgrammLine from "./partials/HpProgrammLine";

const BlockHpRelaxProgramms = ({ blockInfo }) => {
  const { index, suptitle, title, link, programms, backgroundEffects } = blockInfo;
  if (isEmpty(programms)) { return null;}

  const { addToRefs, removeFromRefs } = useRefsContext();
  const localRef = useRef();
  const componentId = useRef(v4()).current;

  let mid = Math.floor(programms.nodes.length / 2);
  let fl = programms?.nodes?.slice(0, mid);
  let ll = programms?.nodes?.slice(mid);

  useEffect(() => {
    const currentRef = localRef.current;
    if (currentRef) {
      addToRefs(componentId, currentRef);
      return () => removeFromRefs(componentId); // Cleanup function
    }
  }, [])

  return (
    <section className={`
      block-relax-programms block-index-${index} overflow-hidden relative z-10 py-50 md:py-75
      ${backgroundEffects?.options?.onEnter?.options?.background == '#FFFFFF' && 'block-bck-spa-white'}
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div className="container mb-20 md:mb-45 3xl:mb-60">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="col-span-2 xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3 md:col-span-12 md:col-start-1"
          >
            <Heading
              tag="div" text={suptitle}
              classes="suptitle mb-20 md:mb-0 text-center text-primary"
            />
            <Heading
              tag="h2" text={title}
              classes="title text-primary text-center"
            />
          </div>
        </div>
      </div>
      <div className="programms -rotate-[3.88deg] flex flex-col gap-[10px] xl:gap-[20px]">
        {!isEmpty(fl) && (
          <HpProgrammLine programms={fl} />
        )}
        {!isEmpty(ll) && (
          <HpProgrammLine programms={ll} type="revert" />
        )}
      </div>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="col-span-2 xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3 md:text-center">
            <Btn type="cta-2" title={link.title} uri={link.url} target={link.target} classes={`btn-relax-prog mt-20 md:inline-flex md:mt-40 3xl:mt-60`} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockHpRelaxProgramms