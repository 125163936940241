import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from 'react';
import Image from "next/image";

import Btn from "../../commons/btn";
import Heading from "../../commons/Heading";

const TechnologySlide = ({ slide, index }) => {
  const scope = useRef();
  const numberRef = useRef();
  const suptitleRef = useRef();
  const btnRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (index == 1) {
      let ctx = gsap.context(() => {
        let tl = gsap.timeline({delay: .5, scrollTrigger: {trigger: scope.current, start: 'top 33%'}});
        tl.from(numberRef.current, {opacity: 0, scale: .98, duration: .3})
        tl.from(suptitleRef.current, {opacity: 0, scale: .98, duration: .3}, '<')
        tl.from(btnRef.current, {opacity: 0, scale: .98, duration: .3})
      }, [scope])

      return () => {
        ctx.revert();
      }
    }
  }, [])


  return (
    <div
      className="techno-slide h-full"
      ref={scope}
    >
      <div className="bg-wrapper absolute z-10 w-full h-full bg-primary">
        <Image
          src={slide.image?.sourceUrl}
          sizes={slide.image.sizes}
          fill={true}
          className='object-cover z-20 opacity-30'
          alt={slide.suptitle}
        />
        <div className="overlay absolute z-30 w-full h-full bg-primary/30"></div>
      </div>
      <div className="container relative h-full z-30">
        <div className="h-full grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3
            md:col-span-6 md:col-start-4 lg:col-span-8 lg:col-start-2 2xl:col-start-3 relative
          ">
            <div className='pt-[50px] lg:pt-0 lg:h-full flex flex-col justify-center'>
              <div className={`index-${index}
                number absolute top-[20%] opacity-30
                lg:relative lg:top-0
              `} ref={numberRef}>
              </div>
              <p className="mb-100 lg:mt-10 lg:mb-30 text-white tracking-[.3em] uppercase" ref={suptitleRef}>{slide.suptitle}</p>
              <h3 className="slide-title mb-25 text-white">
                <div className="flex items-center">
                  <Heading tag="span" text={slide.titleFirstPart} animate={false} />
                  <Image

                    src={slide?.icon?.sourceUrl}
                    width={slide?.icon?.mediaDetails?.width}
                    height={slide?.icon?.mediaDetails?.height}
                    alt={slide.titleFirstPart + ' ' + slide.titleSecondPart}
                    className="ml-15 max-h-25 w-auto h-auto lg:max-h-45"
                  />
                </div>
                <Heading tag="div" text={slide.titleSecondPart} animate={false} />
              </h3>
              <div ref={btnRef}>
                <Btn
                  type="cta-2-2"
                  classes={`md:inline-flex`}
                  title={slide?.link?.title}
                  uri={slide?.link?.url}
                  target={slide?.link?.target}
                  classes={"techno-slide-btn"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechnologySlide