import { isEmpty } from "lodash";

import Link from "next/link";

import ProductsMenu from "./partials/ProductsMenu";

const BlockSubmenu = ({ blockInfo }) => {
  const { index, category, armchairs, accessories, programms, systems } = blockInfo;

  if (isEmpty(category) || isEmpty(programms)) { return null }

  let products = null;
  if (category?.databaseId == process.env.PRODUCT_ARMCHAIRS_CATEGORY_ID) {
    products = armchairs;
  } else if (category?.databaseId == process.env.PRODUCT_PROGRAMM_CATEGORY_ID) {
    products = programms.nodes;
  } else if (category?.databaseId == process.env.PRODUCT_ACCESSORIES_CATEGORY_ID) {
    products = accessories;
  } else if (category?.databaseId == process.env.PRODUCT_SYSTEMS_CATEGORY_ID) {
    products = systems;
  }

  if (products == null) { return null; }


  return (
    <section className={`
      block-product-submenu block-index-${index}
    `}>
      <div className="py-20 border-y border-white/10">
        <div className="container">
          <div className="grid grid-cols-mobile gap-x-mobile items-center xs:grid-cols-normal lg:gap-x-normal">
            <div className="col-span-1 xs:col-span-4 lg:col-span-2">
              <Link href={category.uri} className="flex items-center text-white hover:text-action transition-all">
                <span className="inline-block rotate-[225deg] icon icon-btn-arrow ft-12 mr-10" />
                <span>{category.name}</span>
              </Link>
            </div>
            <div className="col-span-1 xs:col-span-8 relative">
              <ProductsMenu products={products} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockSubmenu