import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
mapboxgl.accessToken = 'pk.eyJ1IjoibXljbGllbnRpc3JpY2giLCJhIjoiY2xpYWY5OG5jMDMydDNxdGVlYmZpcThtbiJ9.TrupB7R4swgTcSY4ZCFqKQ';


import { useEffect, useRef } from "react";

import { useLayoutContext } from "../../../../contexts";

import ShowroomPopup from "./map/ShowroomPopup";
import ResellerPopup from "./map/ResellerPopup";

const ShowroomMap = ({ showrooms }) => {
  if (isEmpty(showrooms)) { return null; }

  const { reloadFlag } = useLayoutContext();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markerPopups = useRef(null);
  const returnLink = useRef(null);
  const mobileMapOptions = {
    flyToCoor: [2.5, 43.5],
    flyToZoom: 4.75,
    animationDuration: 3000
  }
  const desktopMapOptions = {
    flyToCoor: [3.5885477, 46.3606389],
    flyToZoom: 5.33,
    animationDuration: 5500
  }
  const mapOptions = reloadFlag == 'mobile' ? mobileMapOptions : desktopMapOptions;

  const handleReturn = () => {
    if (map.current != null) {
      map.current.flyTo({
        center: mapOptions.flyToCoor,
        zoom: mapOptions.flyToZoom,
        duration: 2000,
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
      returnLink.current.classList.remove('active');

      if (reloadFlag == 'desktop') {
        let popups = markerPopups.current.querySelectorAll('.marker-popup');
        let markers = mapContainer.current.querySelectorAll('.marker-ping');
        popups.forEach(item => (item.classList.remove('active')));
        markers.forEach(item => (item.classList.remove('active')));
      }
    }
  }

  useEffect(() => {
    const power = localStorage.getItem("power");
    if (!map.current) {
      //init
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/myclientisrich/clg2f9s3k00a801qhr3zlppyz',
        center: power == "enough" ? [80, 36] : (reloadFlag == "mobile" ? mobileMapOptions.flyToCoor : desktopMapOptions.flyToCoor),
        zoom: power == "enough" ? 1 : (reloadFlag == "mobile" ? mobileMapOptions.flyToZoom : desktopMapOptions.flyToZoom),
        dragPan: false,
        pitch: 46.25,
        interactive: true,
        passive: true
      });
      // disabled unwanted
      map.current.scrollZoom.disable();
      map.current.doubleClickZoom.disable();

      // addMarker function
      const addMarker = (lng, lat) => {
        let ping = document.createElement('div');
        ping.classList.add('marker-ping');
        let marker = new mapboxgl.Marker(ping)
          .setLngLat([lng,lat])
          .addTo(map.current);

        return marker;
      };

      // Markers configurations
      let markersArray = []
      showrooms.map((item, index) => {
        if (item.type == 'showroom') {
          markersArray[index] = addMarker(item?.showroom?.maps?.long, item?.showroom?.maps?.lat);
        } else {
          markersArray[index] = addMarker(item?.reseller?.maps?.long, item?.reseller?.maps?.lat);
        }
        markersArray[index].getElement().classList.add('type-' + item.type);

        // first marker active on load
        reloadFlag == 'mobile' && index == 0 && markersArray[index].getElement().classList.add('active');

        // On click
        markersArray[index].getElement().addEventListener('click', () => {
          // active on marker
          markersArray.forEach((m) => {
            m.getElement().classList.remove('active');
          });
          markersArray[index].getElement().classList.add('active');
          // active on popup
          let popups = markerPopups.current.querySelectorAll('.marker-popup');
          popups.forEach(popup => {
            popup.classList.remove('active');
            if (popup.dataset.index == 'index-' + index) {
              popup.classList.add('active');
            }
          })
          // flyTo marker
          map.current.flyTo({
            center: reloadFlag == 'mobile' ? [markersArray[index].getLngLat().lng, markersArray[index].getLngLat().lat - 0.5] : [markersArray[index].getLngLat().lng + 0.5, markersArray[index].getLngLat().lat],
            zoom: 8,
            duration: 2000,
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
          });
          returnLink.current.classList.add('active');
        })
      })

      // Animation
      if (power == "enough") {
        ScrollTrigger.create({
          trigger: mapContainer.current,
          start: 'top center',
          onEnter: () => {
            if (map.current != null) {
              map.current.flyTo({
                center: mapOptions.flyToCoor,
                zoom: mapOptions.flyToZoom,
                duration: reloadFlag == 'mobile' ? mobileMapOptions.animationDuration : desktopMapOptions.animationDuration,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
              });
              if (reloadFlag == 'mobile') {
                setTimeout(() => {
                  if (markerPopups.current !=  null) {
                    let firstShowroom = markerPopups.current.querySelector('.marker-popup.showroom-popup:first-child');
                    firstShowroom != null && firstShowroom.classList.add('active');
                  }
                }, reloadFlag == 'mobile' ? mobileMapOptions.animationDuration : desktopMapOptions.animationDuration);
              }
            }
          },
        })
      }
    }
  }, [reloadFlag])

  useEffect(() => {
    if (map.current) { map.current.resize() }
  }, [map.current])

  return (
    <div className="map-scene relative">
      <div
        id={`map`}
        ref={mapContainer}
        className={`w-full aspect-[1/2] xs:aspect-[3/5] sm:aspect-3/4 md:aspect-square lg:aspect-[1728/744] lg:h-auto`}
      />
      <div
        className="
          marker-popups w-[calc(100%_-_10px)] absolute bottom-[10px] left-1/2 -translate-x-1/2
          lg:flex lg:justify-end lg:bottom-[7.5px] lg:left-auto lg:right-0 lg:w-auto lg:translate-x-0 xl:bottom-1/2 xl:translate-y-1/2
        "
        ref={markerPopups}
      >
        {showrooms.map((item, index) => (
          item.type == 'showroom'
          ? <ShowroomPopup key={v4()} item={item.showroom} index={index} />
          : <ResellerPopup key={v4()} item={item.reseller} index={index} />
        ))}
        <div
          className="return-link flex items-center gap-[16px] absolute top-0 left-[10px] -translate-y-[125%] text-white lg:left-[unset] lg:right-[20px]"
          ref={returnLink} onClick={handleReturn}
        >
          <div className="rotate-[90deg]"><span className="icon icon-arrow-down" /></div>
          <span className="underline">Retour</span>
        </div>
      </div>
    </div>
  )
}

export default ShowroomMap