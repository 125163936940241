import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react"

import Btn from "../commons/btn"
import Wysiwyg from "../commons/Wysiwyg"
import Heading from "../commons/Heading"

const BlockPageBanner = ({ blockInfo }) => {
  const { index, title, content, linkList } = blockInfo;
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-page-banner block-index-${index} relative z-10 py-25 lg:py-40
    `} ref={scope}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="main-wrapper
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-10 md:col-start-2
            bg-dark-linear-gradient backdrop-blur-[6px] rounded-[20px] text-white
          ">
            <Heading tag="div" text={title} classes="title-w-em mb-20 md:mb-40" titleWChild={true} animate={false} />
            <Wysiwyg content={content} classes={'mb-30 md:mb-60'} />
            {!isEmpty(linkList) && (
              <div className="grid grid-cols-2 gap-x-normal">
                {linkList.map(item => (
                  <Btn
                    key={v4()}
                    type="link-button"
                    title={item?.link?.title}
                    uri={item?.link?.url}
                    target={item?.link?.target}
                    classes={`col-span-2 mb-10 md:mb-15 lg:mb-0 lg:col-span-1`}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPageBanner