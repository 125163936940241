import { v4 } from "uuid"
import InfiniteCard from "./InfiniteCard"

const InfiniteCardDekstop = ({ cards }) => {
  return (
    <div className="infinite-card-desktop flex justify-center flex-wrap gap-normal">
    {cards.map((item, index) => (
      <div key={v4()} className="item flex-[0_0_31.64%]">
        <InfiniteCard item={item} index={index} />
      </div>
    ))}
  </div>
  )
}

export default InfiniteCardDekstop