import { isEmpty } from "lodash";

import Gallery2 from "./partials/galleries/Gallery2";
import Gallery1 from "./partials/galleries/Gallery1";

const BlockImageGallery = ({ blockInfo }) => {
  const {index, gallery, backgroundEffects} = blockInfo;

  if (isEmpty(gallery)) { return; }

  if (gallery.length == 2 || gallery.length == 3) {
    return (<Gallery2 index={index} gallery={gallery} backgroundEffects={backgroundEffects} />)
  } else {
    return(<Gallery1 index={index} gallery={gallery} backgroundEffects={backgroundEffects} />)
  }
}

export default BlockImageGallery