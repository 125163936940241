import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import { useGlobalContext } from "../../../contexts";

import Heading from "../commons/Heading";
import BlTriptychPart from "../commons/partials/BlTriptychPart";
import Btn from "../commons/btn";

const BlockBlExperience = ({ blockInfo }) => {
  const {index, displayBtn, animate} = blockInfo;
  const { context } = useGlobalContext();

  if (context.isSet == false) {
    return;
  }

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (animate) {
      let ctx = gsap.context(() => {
        gsap.from(scope.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0,
        })
      }, [scope])

      return () => {
        ctx.revert();
      }
    }
  }, [])

  return (
    <section className={`block-bl-experience block-index-${index}`} ref={scope}>
      <div className="container common-block-padding">
        <div className="
          grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal lg:items-center
          bg-dark-linear-gradient rounded-[10px] backdrop-filter-[6px]
        ">
          <Heading
            tag="div"
            text={context?.blTriptych?.title}
            classes={"col-span-2 xs:col-span-12 title title-w-em text-white text-center"}
            titleWChild={true}
            animate={false}
          />
          <div className="col-span-2 xs:col-span-12 flex flex-col items-center md:flex-row md:items-start xl:col-span-10 xl:col-start-2">
            <BlTriptychPart item={context.blTriptych.parts[0]} wShowMore={true} />
            <span className="my-20 ft-55 font-light text-white">+</span>
            <BlTriptychPart item={context.blTriptych.parts[1]} wShowMore={true} />
            <span className="my-20 ft-55 font-light text-white">+</span>
            <BlTriptychPart item={context.blTriptych.parts[2]} wShowMore={true} />
          </div>
        </div>
      </div>
      {displayBtn == true && (
        <div className="common-block-padding text-center">
          <Btn type="shop-outside-header" title="Accéder à la boutique" />
        </div>
      )}
    </section>
  )
}

export default BlockBlExperience