import Image from "next/image"
import Link from "next/link"

const ProgrammCard = ({ programm, loading = "eager" }) => {
  return (
    <Link
      href={programm.uri}
      className="
        programm-card flex flex-col items-center justify-between aspect-[236/268] rounded-[10px] text-center
      "
      style={{ background: programm.acfProgramms.gradient }}
    >
      <Image
        src={programm?.featuredImage?.node?.sourceUrl}
        sizes={programm?.featuredImage?.node?.sizes}
        width={programm?.featuredImage?.node?.mediaDetails?.width}
        height={programm?.featuredImage?.node?.mediaDetails?.height}
        alt={`Cadre représentant le programme ${programm.title}`}
        priority={loading == "eager" ? true : false}
        loading={loading}
        className="programm-img"
      />
      <span
        className="programm-title flex items-center justify-center
          ft-9-to-18 leading-[144%] font-light tracking-[.3em] uppercase text-white
      "><span>{programm.title}</span></span>
    </Link>
  )
}

export default ProgrammCard