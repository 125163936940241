import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Heading from "../commons/Heading";
import Btn from "../commons/btn";
import PartnersSlider from "./partials/PartnersSlider";
import TestimoniesSlider from "./partials/TestimoniesSlider";

const BlockPartners = ({ blockInfo }) => {
  const { index, title, displayPartners, displayBw, displayLinearGradient, displayTestimonies, displayLink, partners, testimonies } = blockInfo
  if (displayPartners == false && displayTestimonies == false) {
    return;
  }

  const scope = useRef();
  const partnersSliderRef = useRef();
  const testimoniesSliderRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      if (partnersSliderRef.current != null) {
        gsap.from(partnersSliderRef.current, {
          scrollTrigger: {
            trigger: partnersSliderRef.current,
            start: 'top 66%'
          },
          opacity: 0,
          y: 20,
          duration: .5
        })
      }
      if (testimoniesSliderRef.current != null) {
        gsap.from(testimoniesSliderRef.current, {
          scrollTrigger: {
            trigger: testimoniesSliderRef.current,
            start: 'top 66%'
          },
          opacity: 0,
          y: 20,
          duration: .5
        })
      }
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section
      className={`
        block-partners block-index-${index} relative z-10 overflow-hidden
      `}
      ref={scope}
    >

      <div className={`pt-50 md:pt-80 md:pb-60 ${displayLinearGradient == true && `bg-white`}`}>
        <div className="container">
          <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
            <div className="col-span-2 mb-20 xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3 md:col-span-12">
              <Heading tag="h2" text={title} classes="suptitle text-center" />
            </div>
            {displayPartners == true && (
              <div className="col-span-2 xs:col-span-10 xs:col-start-2
              sm:col-span-8 sm:col-start-3 md:col-span-12" ref={partnersSliderRef}>
                <PartnersSlider bw={displayBw} partners={partners} />
              </div>
            )}
          </div>
        </div>
      </div>

      {displayTestimonies == true && (
        <div className={`bg-blue-radial-gradient`}>
          <div className="container pb-50 md:pb-80">
            <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
                <div className="col-span-2 xs:col-span-10 xs:col-start-2
                sm:col-span-8 sm:col-start-3 mt-40 md:col-span-11 md:col-start-2" ref={testimoniesSliderRef}>
                  <TestimoniesSlider testimonies={testimonies} />
                </div>
            </div>
            {displayLink == true && (
              <div className="col-span-2 xs:col-span-10 xs:col-start-2
                sm:col-span-8 sm:col-start-3 md:col-span-12 sm:text-center">
                <Btn
                  type="cta-2"
                  title={'Nos références'}
                  uri={'/qui-sommes-nous/nos-references/'}
                  classes={'mt-50 sm:inline-flex'}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  )
}

export default BlockPartners