import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css';

import CommercialAgentsSlide from "./CommercialAgentsSlide";

const PageCommercialAgentsSlider = ({ commercialAgents }) => {

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      breakpoints={{
        500: {
          slidesPerView: 1.15
        },
        768: {
          slidesPerView: 2.5
        },
        1024: {
          slidesPerView: 3.5,
          spaceBetween: 25
        }
      }}
    >
      {commercialAgents.map((item, index) => (
        <SwiperSlide key={v4()}>
          <CommercialAgentsSlide index={index} item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default PageCommercialAgentsSlider