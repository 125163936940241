import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import Btn from "../../commons/btn";
import Loader from "../../commons/Loader";

const NewsTagsList = ({tags, handleSearch, activeTag, isLoading}) => {
  const menuRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handleScroll = () => {
    if (menuRef != null) {
      let scollLeftMax = menuRef.current.scrollWidth - menuRef.current.clientWidth;
      if (menuRef.current.scrollLeft <= 10) {
        !prevRef.current.classList.contains('hidden') && prevRef.current.classList.add('hidden')
      } else if (menuRef.current.scrollLeft > 10) {
        prevRef.current.classList.contains('hidden') && prevRef.current.classList.remove('hidden')
      }
      if (menuRef.current.scrollLeft < scollLeftMax - 10) {
        nextRef.current.classList.contains('hidden') && nextRef.current.classList.remove('hidden');
      } else if (menuRef.current.scrollLeft >= scollLeftMax - 10) {
        !nextRef.current.classList.contains('hidden') && nextRef.current.classList.add('hidden');
      }
    }
  }

  const handleScrollLeft = () => {
    if (menuRef != null) {
      menuRef.current.scrollTo({
        top: 0,
        left: menuRef.current.scrollLeft - 150,
        behaviour: 'smooth'
      });
    }
  }
  const handleScrollRight = () => {
    if (menuRef != null) {
      menuRef.current.scrollTo({
        top: 0,
        left: menuRef.current.scrollLeft + 150,
        behaviour: 'smooth'
      });
    }
  }

  const handleOverflow = () => {
    if (menuRef.current.scrollWidth > menuRef.current.clientWidth) {
      nextRef.current.classList.remove('hidden');
      menuRef.current.querySelector('.inside').classList.remove('justify-center');
    } else {
      nextRef.current.classList.add('hidden');
      menuRef.current.querySelector('.inside').classList.add('justify-center');
    }
  }

  useEffect(() => {
    handleOverflow();

    window.addEventListener('resize', handleOverflow, {passive: true})
    return () => {
      window.removeEventListener('resize', handleOverflow)
    }
  })

  return (
    <div className="tags-menu-wrapper relative">
      <Loader classes={`!w-[40px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transition-all pointer-events-none ${isLoading ? 'opacity-100' : 'opacity-0'}`} />
      <div className={`transition-all ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
        <div
          className="menu-prev h-full absolute z-20 top-1/2 -translate-y-1/2 left-0 flex items-center justify-center hidden"
          ref={prevRef}
          onClick={handleScrollLeft}
        >
          <div className="rotate-90">
            <span className="icon icon-chevron text-white"/>
          </div>
        </div>
        <div className="tags-menu overflow-y-hidden scroll-smooth text-center" onScroll={handleScroll} ref={menuRef}>
          <div className="inside flex items-center gap-x-20 px-20">
            <Btn
              type='tag-btn'
              title={'Tout'}
              id={0}
              active={activeTag == 0 && true}
              handleSearch={handleSearch}
            />
            {tags?.map(tag => (
              <Btn
                key={v4()}
                type='tag-btn'
                title={tag.name}
                id={tag.tagId}
                active={activeTag == tag.tagId && true}
                handleSearch={handleSearch}
              />
            ))}
            {/* {isLoading == true && ()} */}
          </div>
        </div>
        <div
          className={`menu-next absolute z-20 top-1/2 -translate-y-1/2 right-0 h-full flex items-center justify-center hidden`}
          ref={nextRef}
          onClick={handleScrollRight}
        >
          <div className="-rotate-90">
            <span className="icon icon-chevron text-white"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsTagsList