import { isEmpty } from "lodash";
import parse from 'html-react-parser';
import { gsap } from "gsap";

import { useEffect, useRef } from "react";
import Image from "next/image";

const MeaCarac = ({index, carac, position}) => {
  if (isEmpty(carac.text) || isEmpty(carac.image) || isEmpty(position)) { return; }

  const scope = useRef();
  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(scope.current, {opacity: 1, scale: 1, duration: .5, delay: index*.5});
    }, [scope]);

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <div
      className={`mea-carac absolute bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] text-white opacity-0 scale-[0.98] ${position}`}
      ref={scope}
    >
      <div className="flex items-center">
        <Image
          src={carac?.image?.sourceUrl}
          sizes={carac?.image?.sizes}
          width={carac?.image?.mediaDetails?.width}
          height={carac?.image?.mediaDetails?.height}
          alt={carac.text.replace(/<\/?[^>]+(>|$)/g, "")}
          className="w-auto h-30 mr-15 md:h-40 md:mr-20 2xl:h-50 2xl:mr-30"
        />
        <span className="ft-12 font-light leading-[166%] tracking-[3.6px] uppercase">{parse(carac.text)}</span>
      </div>
      <div className="icon flex items-center justify-center rounded-full">
        <span className="icon-plus ft-20" />
      </div>
    </div>
  )
}

export default MeaCarac