import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import Heading from "../commons/Heading";
import Btn from "../commons/btn";
import NewsSlider from "./partials/NewsSlider";

const BlockHpNews = ({ blockInfo }) => {
  const { index, suptitle, title, link, lastnews, backgroundEffects } = blockInfo;


  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  return (
    <section
      className={`
        block-hp-news block-index-${index}
      `} data-backgroundeffects={JSON.stringify(backgroundEffects?.options)} ref={localRef}>
        {!isEmpty(lastnews) && (
          <div className="relative z-10 py-50 md:py-80 3xl:pt-150 5xl:pt-180 bg-blue-radial-gradient">
            <div className="container">
              <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
                <div className="col-span-2 xs:col-span-10 xs:col-start-2
                    sm:col-span-8 sm:col-start-3 md:col-span-12 xl:col-span-11 mb-50">
                  <Heading tag="div" text={suptitle} classes="suptitle mb-25" />
                  <Heading tag="div" text={title} classes="title-w-img" titleWChild={true}  />
                </div>
                <div className="col-span-2 mb-40 xs:col-span-10 xs:col-start-2
                    sm:col-span-8 sm:col-start-3 md:col-span-12">
                  <NewsSlider lastnews={lastnews} />
                </div>
                <div className="col-span-2 xs:col-span-10 xs:col-start-2
                    sm:col-span-8 sm:col-start-3 md:col-span-12">
                  <Btn
                    type="cta-2"
                    title={link?.title}
                    uri={link?.url}
                    target={link?.target}
                    classes=""
                  />
                </div>
              </div>
            </div>
          </div>
        )}
    </section>
  )
}

export default BlockHpNews