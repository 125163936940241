import Link from "next/link"
import { useRouter } from "next/router";

const SocialSharing = ({style = 'normal'}) => {
  const router = useRouter();

  return (
    <div className={`social-medias share text-center lg:text-left`}>
      <div className={`inline-block ${style == 'alt' && 'flex items-center'}`}>
        <div className={`ft-14 font-extralight text-text text-left mb-10 ${style == 'alt' && 'mr-10'}`}>Partager sur :</div>
        <div className="flex items-center gap-x-10">
          <Link
            href={`https://www.facebook.com/sharer/sharer.php?u=https://www.brainlight-france.fr` + router.asPath}
            target='_blank'
            className={`hover:text-action transition-all ${style == 'alt' && 'text-white/40'}`}
          >
            <span className={`icon icon-facebook ft-27`}></span>
          </Link>
          <Link
            href={`https://twitter.com/intent/tweet?url=https://www.brainlight-france.fr` + router.asPath}
            target='_blank'
            className={`hover:text-action transition-all ${style == 'alt' && 'text-white/40'}`}
          >
            <span className={`icon icon-twitter ft-27`}></span>
          </Link>
          <Link
            href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.brainlight-france.fr` + router.asPath}
            target='_blank'
            className={`hover:text-action transition-all ${style == 'alt' && 'text-white/40'}`}
          >
            <span className={`icon icon-linkedin ft-27`}></span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SocialSharing