import { v4 } from "uuid";
import parse from 'html-react-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import Image from "next/image";

import Btn from "../commons/btn";

const BlockPageBannerBsb = ({ blockInfo }) => {
  const { index, suptitle, title, contentUppercase, content, image, link, backgroundEffects } = blockInfo;
  const scope = useRef();


  // background effects
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = scope.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        opacity: 0,
        y: 20,
        duration: .5
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-hp-banner block-index-${index} relative z-10 py-50
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={scope}>
      <div className="container">
        <div className="
          grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal md:items-center
          bg-dark-linear-gradient backdrop-blur-[6px] rounded-[20px] px-20 py-50 xl:px-0
        ">
          <div className="col-span-2 xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3 md:col-span-5">
            <Image
              src={image?.sourceUrl}
              width={image?.mediaDetails?.width}
              height={image?.mediaDetails?.height}
              alt={title}
              className="w-[80%] mx-auto"
            />
          </div>
          <div className="col-span-2 xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3 md:col-span-6 flex flex-col mt-30 md:mt-0 text-white">
            <div className="suptitle">{suptitle}</div>
            <h2 className="h4 my-15">{title}</h2>
            <div className="uppercase ft-14 leading-[186%] tracking-[.3em] font-light">{parse(contentUppercase)}</div>
            <div className="mt-15 mb-30 ft-14 leading-[157%] font-extralight">{content}</div>
            <Btn
              type="cta-light-2"
              title={link.title}
              uri={link.url}
              target={link.target}
              textClasses={`decoration-blYellow text-white`}
              iconClasses={`text-blYellow`}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPageBannerBsb;