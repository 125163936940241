import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css';

import NewsSlide from "./NewsSlide";

const NewsSlider = ( { lastnews }) => {

  return (
    <div className="last-news-slider">
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        breakpoints={{
          500: {
            slidesPerView: 1.5,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 3.5,
            spaceBetween: 30
          },
          1280: {
            slidesPerView: 3.5,
            spaceBetween: 60
          },
          1536: {
            slidesPerView: 3.5,
            spaceBetween: 90
          },
        }}
      >
        {lastnews.map((item, index) => (
          <SwiperSlide key={v4()}>
            <NewsSlide news={item} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default NewsSlider