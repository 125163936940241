const ProgressBar = ({ progress }) => {

  return (
    <div className="progress-bar grow relative">
      <div className="not-filled w-full h-15"/>
      <div className={`filled w-0 h-15 absolute top-0 left-0 transition-all`} style={{width: progress + '%'}}/>
    </div>
  );
};

export default ProgressBar;