import { v4 } from "uuid";
import { gsap } from "gsap";

import { useEffect, useRef, useState } from "react"

const ScrollingSentence = ({ sentences }) => {

  const scope = useRef();
  const firstLine = useRef();
  const secondLine = useRef();
  const [secondLineSentences, setSecondlineSentences] = useState([]);

  function shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  useEffect(() => {
    setSecondlineSentences(shuffle([...sentences]));
  }, [sentences]);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set(firstLine.current, {xPercent: -45})
      gsap.set(secondLine.current, {xPercent: -35})

      let flTl = gsap.timeline({repeat: -1, scrollTrigger: {trigger: scope.current, start: 'top 90%'}});
      flTl.to(firstLine.current, {xPercent: -60, duration: 90})
      flTl.to(firstLine.current, {xPercent: -10, duration: 90})

      let slTl = gsap.timeline({repeat: -1, scrollTrigger: {trigger: scope.current, start: 'top 90%'}});
      slTl.to(secondLine.current, {xPercent: -10, duration: 90})
      slTl.to(secondLine.current, {xPercent: -60, duration: 90})
    }, scope);

    return () => {
      ctx.revert();
    }
  }, []);


  return (
    <div className="overflow-hidden">
      <div className="scrolling-sentence -rotate-[1.62deg]" ref={scope}>
        <div className="w-max flex items-center" ref={firstLine}>
          {sentences.map(sentence => (
            <div key={v4()} className="flex items-center">
              <span className="sentence">{sentence.sentence}</span>
              <span className="icon icon-star"></span>
            </div>
          ))}
        </div>
        <div className="w-max flex items-center" ref={secondLine}>
          {secondLineSentences.map(sentence => (
            <div key={v4()} className="flex items-center">
              <span className="sentence">{sentence.sentence}</span>
              <span className="icon icon-star"></span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ScrollingSentence