import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from 'react';

import Image from 'next/image';
import Btn from '../../commons/btn';

const TechnologyFrame = ({ frame }) => {
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 50%'
        },
        opacity: 0,
        x: 20,
        duration: .5
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])


  return (
    <div className="
      technology-frame inline-flex items-center px-23 py-33 rounded-[20px] bg-dark-linear-gradient backdrop-blur-[6px]
      md:flex-col md:items-start md:max-w-full xl:max-w-none
    " ref={scope}>
      <Image
        src={frame?.icon?.sourceUrl}
        width={frame?.icon?.mediaDetails?.width}
        height={frame?.icon?.mediaDetails?.height}
        alt={frame.title}
        className="mr-25 md:mr-0 md:mb-15"
      />
      <div className='flex flex-col text-white'>
        <span className='tf-title font-light uppercase'>{frame.title}</span>
        <span className='tf-text hidden md:block ft-14 leading-[157%] font-extralight'>{frame.text}</span>
        <Btn
          type='cta-light-2'
          title={frame?.link?.title}
          uri={frame?.link?.url}
          target={frame?.link?.target}
          classes={'mt-10 md:mt-25'}
          textClasses={`text-white decoration-blBlue`}
          iconClasses={`text-blBlue`}
        />
      </div>
    </div>
  )
}

export default TechnologyFrame