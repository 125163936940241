import Btn from "../../commons/btn"

const BottomBlocksBtns = () => {
  return (
    <div className="container common-block-padding-top">
      <div className="grid grid-cols-mobile xs:grid-cols-normal gap-x-mobile lg:gap-x-normal">
        <div className="
          col-span-2 xs:col-span-12 sm:col-span-10 sm:col-start-2 md:col-span-12
          text-center md:text-right flex flex-col gap-20 md:flex-row md:justify-center
        ">
          <Btn type="normal-test" classes={'md:inline-flex mb-20 md:mb-0'} />
          <Btn type="shop-outside-header" title={'Accéder à la boutique'}  />
        </div>
      </div>
    </div>
  )
}

export default BottomBlocksBtns