import { isEmpty } from "lodash";

import { useEffect, useState } from "react";
import Select from 'react-select';
import Link from "next/link";

import { useGlobalContext } from "../../../../contexts";

import Field from "../Field";

function formTestLocation({ handler, isLoading, isSent, hasError, invalidFields, systems }) {
  const { context } = useGlobalContext();
  const [formState, setFormState] = useState({})
  const [open, setOpen] = useState(false);

  const handleFieldChange = (field, e) => {
    let fieldValue;
    if (e && e.target) {
      fieldValue = e.target.value;
    } else if (e) {
      fieldValue = e;
    }

    if (fieldValue !== undefined) {
      setFormState({
        ...formState,
        [field]: fieldValue,
      });

      } else {
        setFormState({
          ...formState,
          [field]: fieldValue,
        });
      }
      if (e.target !== undefined) {
        if(e.target.value !== "") {
          if(e.target.previousSibling !== null) {
            e.target.previousSibling.style.opacity = 0;
          }
        }
        else {
          if(e.target.previousSibling !== null) {
            e.target.previousSibling.style.opacity = 1;
          }
        }
      }
    }

  const handleFormSubmit = (e) => {
    handler(e, formState)
  }

  let systemsOptionsDuration = [];
  if (!isEmpty(context?.locationFormSelectGr?.locationTime)) {
    context?.locationFormSelectGr?.locationTime.forEach(time => {
      let data = {
        "value": time.value,
        "label": time.value
      }
      systemsOptionsDuration.push(data);
    })
  }

  let systemsOptions = [];
  if (!isEmpty(context?.locationFormSelectGr?.systemsSelect)) {
    context?.locationFormSelectGr?.systemsSelect.forEach(system => {
      let data = {
        "value": system.value,
        "label": system.value
      }
      systemsOptions.push(data);
    })
  } else {
    systems?.forEach(system => {
      let data = {
        "value": system?.title,
        "label": system?.title
      }
      systemsOptions.push(data);
    })
  }

  // ACCEPTANCE
  const AcceptancePlaceholder = () => {
    return (
      <div className="wysiwyg acceptance md:flex md:items-center text-left">
        <span className="mr-1">J’accepte que mes données soient utilisées.</span>
        <Link href={context?.links?.privacyPolicy?.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')} className="acceptance-link" target="_blank">En savoir plus</Link>
      </div>
    )
  }

  const formFields =  [
    {
      type: 'select',
      name: 'dureelocation',
      placeholder: "Durée de location",
      classes: "col-span-12",
      required: true,
      invalid: false,
      options: systemsOptionsDuration,
    },
    {
      type: 'select',
      name: 'systemlocation',
      placeholder: "Système à louer",
      classes: "col-span-12",
      required: true,
      invalid: false,
      options: systemsOptions,
    },
    {
      type: 'text',
      name: 'Nom',
      placeholder: "Nom",
      classes: "col-span-12 xl:col-span-6",
      required: true,
      invalid: false
    },
    {
      type: 'text',
      name: 'Prenom',
      placeholder: "Prénom",
      classes: "col-span-12 xl:col-span-6",
      required: true,
      invalid: false
    },
    {
      type: 'email',
      name: 'Email',
      placeholder: "Adresse e-mail",
      classes: "col-span-12 xl:col-span-6",
      required: true,
      invalid: false
    },
    {
      type: 'tel',
      name: 'phone',
      placeholder: "Téléphone",
      classes: "col-span-12 xl:col-span-6",
      required: false,
      invalid: false
    },
    {
      type: 'textarea',
      name: 'message',
      placeholder: `Message\n(Merci d'indiquer vos disponibilités)`,
      classes: "col-span-12",
      required: true,
      invalid: false
    },
    {
      type: 'checkbox',
      name: 'acceptance',
      placeholder: AcceptancePlaceholder,
      classes: "acceptance-wrapper col-span-12",
      required: true,
      invalid: false
    }
  ]

  const [fieldsState, setFieldsState] = useState(formFields)
  useEffect(() =>  {
    formFields.map((field) => (
      invalidFields?.map((item) => {
        if(item.field == field.name) {
          field.invalid = item.message;
        }
      })
    ))
    setFieldsState(formFields);
  }, [invalidFields])

  return (
    <div className="w-full grid grid-cols-mobile lg:grid-cols-normal">
    <span className="triangle absolute left-0 top-0"></span>
    <form onSubmit={handleFormSubmit} className="mb-4 mt-12 col-span-2 lg:col-start-4 lg:col-span-6 lg:gap-x-[20px] grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
      {fieldsState?.map((item, index) => {
        if (item.type === 'select') {
          return (
            <div className={`${item.classes} custom-select ${index === 1 ? ' mb-[20px] ' : ''}`} key={index} onClick={() => setOpen(!open)}>
              {/* <label className="block font-medium text-gray-700">{item.name}</label> */}
              <Select
                options={item.options}
                placeholder={item.placeholder}
                isSearchable={false}
                onBlur={() => setOpen(false)}
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(selectedOption) => handleFieldChange(item.name, selectedOption.value)}
              />
              {item.invalid != false ? (<span className="pt-2 font-sans block text-red-500">{item.invalid}</span>) :  null}
            </div>
          );
        } else {
          return (
            <Field key={index} data={item} handleFieldChange={handleFieldChange} />
          );
        }
      })}
      <div className={`my-10 sendInfos opacity-0 duration-200 p-12 font-sans col-span-12 w-full text-white ${isSent ? "block bg-green-500 opacity-100" : null} ${hasError ? "block bg-red-500 opacity-100" : null} ${hasError || isSent ? "block" : "hidden"}`}>
        {hasError ? hasError : null}
        {isSent ? <span>Votre message a été envoyé.</span> : null}
      </div>
      <div className="col-span-2 xs:col-span-12 text-center sm:text-end pt-[10px]">
        <button className="flex items-center justify-end rounded-full bg-btn-linear-gradient ml-auto py-15 px-40 mb-[60px] mt-[20px]" type="submit">
          {isLoading ? (
            <span className="font-sans text-center">En cours d'envoi</span>
          ) : (
            <span className="font-sans text-center">Envoyer</span>
          )}
        </button>
      </div>
    </form>
  </div>
  )
}

export default formTestLocation;