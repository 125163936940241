import { isEmpty } from "lodash";

import Heading from "../commons/Heading";
import ProductTechnologies from "./partials/ProductTechnologies";

const BlockTechnologies = ({ blockInfo}) => {
  const { index, title, technologies } = blockInfo;
  if (isEmpty(technologies)) { return; }

  return (
    <section className={`
      block-technologies block-index-${index} common-block-padding overflow-hidden
    `} id="caracs">
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-12">
            <Heading
              tag="div" text={title} titleWChild={true} animate={false}
              classes={'title-w-em text-white mb-30 lg:mb-45 xl:mb-70 3xl:mb-90 4xl:mb-120'}
            />
          </div>
          <div className="col-span-2 xs:col-span-12">
            <ProductTechnologies technologies={technologies} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockTechnologies