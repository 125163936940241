import { isEmpty } from "lodash";
import { v4 } from "uuid";

import ArmchairCard from "./partials/ArmchairCard";
import ComparatorBanner from "../commons/ComparatorBanner";

const BlockListingArmchairs = ({ blockInfo }) => {
  const { index, displayComparator, title, text, linkText, armchairs } = blockInfo;
  if (isEmpty(armchairs)) { return null;}

  return (
    <section className={`
      block-cat-listing-armchairs block-index-${index} common-block-padding
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile gap-y-mobile xs:grid-cols-normal lg:gap-normal">
          {armchairs?.map((product, index) => (
            <ArmchairCard
              key={v4()}
              index={index}
              product={product}
              animate={index >= 2 ? true : false}
              classes={`col-span-12 xs:col-span-10 xs:col-start-2 md:col-span-6 lg:col-span-5 ${index%2 == 0 && 'lg:col-start-2'}`}
            />
          ))}
          {displayComparator == true && (
            <ComparatorBanner title={title} text={text} linkText={linkText} />
          )}
        </div>
      </div>
    </section>
  )
}

export default BlockListingArmchairs