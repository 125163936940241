import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Heading from "../commons/Heading";

const BlockColorChoice = ({ blockInfo }) => {
  const {index, title, text, colors } = blockInfo;
  if (isEmpty(colors)) { return; }

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-color-choice block-index-${index} common-block-padding
    `} ref={scope}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="
            col-span-2 px-20 xs:col-span-12 sm:col-span-10 sm:col-start-2
            common-block-padding bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] text-white text-center
          ">
            <Heading tag="h2" text={title} animate={false} classes={"h4"} />
            <p className="mt-15 mb-30">{text}</p>
            <div className="flex flex-wrap items-center justify-center">
              {colors.map(color => (
                <div className="color-choice-item relative" key={v4()}>
                  <div
                    className={`relative w-75 h-75 mx-15 rounded-[10px] mb-30 overflow-hidden`}
                    style={{backgroundColor: color.color}}
                  >
                    <div
                      className="w-full h-full absolute -bottom-1/2 -right-1/2 -rotate-[45deg]"
                      style={{backgroundColor: color.sColor}}
                    />
                  </div>
                  {!isEmpty(color.name) && (<div className="tooltip-text">{color.name}</div>)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockColorChoice