import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react'

const BuyButton = ({ code, className = "" }) => {
  if (isEmpty(code)) { return null; }
  const bbRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && bbRef.current !== null) {
      bbRef.current.innerHTML = code;

      const scripts = bbRef.current.getElementsByTagName('script');
      for (let script of scripts) {
        eval(script.innerHTML);
      }
    }
  }, []);

  return <div ref={bbRef} className={`buy-button-wrapper${className ? ` ${className}` : ''}`} />;
}

export default BuyButton