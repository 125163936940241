import { isEmpty } from "lodash";
import { v4 } from "uuid";

import TeamMemberCard from "./partials/TeamMemberCard";

const BlockPageTeamBig = ( {blockInfo} ) => {
  const { index, teamMembers } = blockInfo;
  if (isEmpty(teamMembers?.nodes)) { return null; }

  let leftCol = [];
  let rightCol = [];
  teamMembers?.nodes.forEach((item, index) => {
    if (index % 2 == 0) {
      leftCol.push(item);
    } else {
      rightCol.push(item);
    }
  });

  return (
    <section className={`
      block-page-team-big block-index-${index} relative z-10
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          {!isEmpty(leftCol) && (
            <div className="
              col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-6
              lg:col-span-5 lg:col-start-2
            ">
              {leftCol.map((item, index) => (
                <TeamMemberCard key={v4()} item={item} index={index} type={`left`} />
              ))}
            </div>
          )}
          {!isEmpty(rightCol) && (
            <div className="
              col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-6
              lg:col-span-5
            ">
              {rightCol.map((item, index) => (
                <TeamMemberCard key={v4()} item={item} index={index+leftCol.length} type={`right`} />
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default BlockPageTeamBig