import { v4 } from "uuid"

import TechnologyCard from "../TechnologyCard"

const TechnoDesktop = ({ technologies }) => {
  return (
    <div className={`techno-desktop flex flex-wrap gap-normal ${technologies?.length <= 4 && 'sub-4'}`}>
      {technologies.map((item, index) => (
        <div key={v4()} className="item flex-[0_0_31.64%] xl:flex-[0_0_23.45%]">
          <TechnologyCard item={item} index={index} />
        </div>
      ))}
    </div>
  )
}

export default TechnoDesktop