import { isEmpty } from "lodash";

import Heading from "../commons/Heading";
import PageCommercialAgentsSlider from "./partials/PageCommercialAgentsSlider";

const BlockPageSliderCommercialAgents = ({ blockInfo }) => {
  const { index, title, intro, commercialAgents } = blockInfo;
  if (isEmpty(commercialAgents)) { return null; }

  return (
    <section className={`
      block-page-slider-testimonies block-index-${index} relative z-10 py-50 lg:py-80
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3
            text-white
          ">
            <Heading tag="div" text={title} classes="title-w-em" titleWChild={true} />
          </div>
            <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 3xl:col-span-6 3xl:col-start-3
            text-white
          ">
            <Heading tag="h5" text={intro} />
          </div>
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-12
            mt-30 md:mt-60 lg:mt-90"
          >
            <PageCommercialAgentsSlider commercialAgents={commercialAgents} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPageSliderCommercialAgents