import { isEmpty } from "lodash";

import Heading from "../commons/Heading";
import ShowroomMap from "./partials/ShowroomMap";

const BlockMap = ({ blockInfo }) => {
  const {index, title, introduction, showrooms} = blockInfo;
  if (isEmpty(showrooms)) { return null; }

  return (
    <section className={`block-map block-index-${index}`}>
      <div className="container common-block-padding">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="col-span-2 xs:col-span-12 text-white">
            <Heading tag="div" text={title} classes="title-w-em text-center" titleWChild={true} />
          </div>
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 2xl:col-span-6 2xl:col-start-4 text-white">
            <Heading tag="h5" text={introduction} classes={'text-center'} />
          </div>
        </div>
      </div>


      <ShowroomMap showrooms={showrooms} />
    </section>
  )
}

export default BlockMap