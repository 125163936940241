import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react"
import Image from "next/image"
import { useRefsContext } from "../../../../../contexts";


const Gallery2 = ({ index, gallery, backgroundEffects }) => {
  const scope = useRef(null);
  const inside = useRef(null);

  // background effects
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = scope.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (scope?.current != null && inside.current != null) {
      let ctx = gsap.context(() => {
        gsap.to(inside.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'center bottom',
            end: 'bottom center',
            onUpdate: self => {
              let progress = self.progress.toFixed(3);
              let TotalWidthToScroll = inside?.current?.scrollWidth - inside?.current?.clientWidth
              let widthToScroll = TotalWidthToScroll * progress;

              scope?.current?.scrollTo({
                top: 0,
                left: widthToScroll,
                behaviour: 'smooth'
              });
            }
          },
        })
      }, scope);

      return () => {
        ctx.revert();
      }
    }
  }, [])

  return (
    <section className={`
    block-gallery type-2 block-index-${index} overflow-hidden w-full
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={scope}>
      <div className="flex flex-nowrap aspect-[1512/719]" ref={inside}>
        {gallery.map(item => (
          <Image
            key={v4()}
            src={item?.sourceUrl}
            width={item?.mediaDetails?.width}
            height={item?.mediaDetails?.height}
            alt={item?.altText}
            className="h-full w-auto"
          />
        ))}
      </div>
    </section>
  )
}

export default Gallery2