import { isEmpty } from "lodash";
import parse from 'html-react-parser';

import { useState } from "react";

import MultipleAudioComponent from "./MultipleAudioComponent";

const GlobalPreviewAudio = ({ audios }) => {
  if (isEmpty(audios)) { return null; }

  const [listening, setListening] = useState(audios[0]);
  const [playing, setIsPlaying] = useState(false);

  return (
    <div className="
      global-preview-audio bg-audio-linear-gradient p-20 xl:p-40 rounded-[10px] backdrop-blur-[6px]
      col-span-2 xs:col-span-12 lg:col-span-4 xl:col-span-6 flex justify-center items-center
    ">
      <div className="h-full grow flex flex-col justify-between text-center">
        <div className="suptitle flex-1 flex items-center justify-center">{playing ? 'En cours : ' : 'Un aperçu ?'}</div>
        <h2 className="h4 flex-1 leading-[110%] flex items-center justify-center">{playing ? listening?.title : parse('Écoutez l\'ensemble <br class="block" />des extraits')}</h2>
        <MultipleAudioComponent
          audios={audios}
          listening={listening}
          setIsListening={setListening}
          playing={playing}
          setIsPlaying={setIsPlaying}
        />
      </div>
    </div>
  )
}

export default GlobalPreviewAudio