import { v4 } from "uuid";

import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

import Btn from "../../commons/btn";

const ProductsMenu = ({ products }) => {
  const router = useRouter();
  const productsMenuRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handleScroll = () => {
    if (productsMenuRef != null) {
      let scollLeftMax = productsMenuRef.current.scrollWidth - productsMenuRef.current.clientWidth;
      if (productsMenuRef.current.scrollLeft <= 10) {
        !prevRef.current.classList.contains('hidden') && prevRef.current.classList.add('hidden')
      } else if (productsMenuRef.current.scrollLeft > 10) {
        prevRef.current.classList.contains('hidden') && prevRef.current.classList.remove('hidden')
      }
      if (productsMenuRef.current.scrollLeft < scollLeftMax - 10) {
        nextRef.current.classList.contains('hidden') && nextRef.current.classList.remove('hidden');
      } else if (productsMenuRef.current.scrollLeft >= scollLeftMax - 10) {
        !nextRef.current.classList.contains('hidden') && nextRef.current.classList.add('hidden');
      }
    }
  }

  const handleScrollLeft = () => {
    if (productsMenuRef != null) {
      productsMenuRef.current.scrollTo({
        top: 0,
        left: productsMenuRef.current.scrollLeft - 150,
        behaviour: 'smooth'
      });
    }
  }
  const handleScrollRight = () => {
    if (productsMenuRef != null) {
      productsMenuRef.current.scrollTo({
        top: 0,
        left: productsMenuRef.current.scrollLeft + 150,
        behaviour: 'smooth'
      });
    }
  }

  const handleOverflow = () => {
    if (productsMenuRef.current.scrollWidth > productsMenuRef.current.clientWidth) {
      nextRef.current.classList.remove('hidden');
      prevRef.current.parentNode.parentNode.classList.add('lg:col-span-10');
    } else {
      nextRef.current.classList.add('hidden');
      productsMenuRef.current.querySelector('.inside') != null && productsMenuRef.current.querySelector('.inside').classList.add('justify-center');
    }
  }

  useEffect(() => {
    handleOverflow();

    window.addEventListener('resize', handleOverflow, {passive: true})
    return () => {
      window.removeEventListener('resize', handleOverflow)
    }
  })

  return (
    <div className="products-menu-wrapper">
      <div
        className="menu-prev h-full absolute z-20 top-1/2 -translate-y-1/2 left-0 flex items-center justify-center hidden"
        ref={prevRef}
        onClick={handleScrollLeft}
      >
        <div className="rotate-90">
          <span className="icon icon-chevron text-white"/>
        </div>
      </div>
      <div className="products-menu overflow-y-hidden scroll-smooth" onScroll={handleScroll} ref={productsMenuRef}>
        <div className="inside flex items-center gap-x-20 px-20">
          {products?.map(product => (
            <Btn
              key={v4()}
              type='product-btn'
              title={product.title}
              uri={product.uri}
              active={router.asPath == product.uri ? true : false}
              font={product?.acfArmchairs?.titleFont}
            />
          ))}
        </div>
      </div>
      <div
        className={`menu-next absolute z-20 top-1/2 -translate-y-1/2 right-0 h-full flex items-center justify-center hidden`}
        ref={nextRef}
        onClick={handleScrollRight}
      >
        <div className="-rotate-90">
          <span className="icon icon-chevron text-white"/>
        </div>
      </div>
    </div>
  )
}

export default ProductsMenu