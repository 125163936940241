import { isEmpty } from "lodash";
import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import Image from "next/image";

import Heading from "../commons/Heading";
import Btn from "../commons/btn";

const BlockBannerAccessories = ({ blockInfo }) => {
  const { index, title, image, text, link, backgroundEffects } = blockInfo;
  if (isEmpty(image)) { return; }

  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  // component animation
  const scope = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-banner-accessories block-index-${index} fb-block-padding
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="col-span-2 xs:col-span-12 mb-20 md:mb-45 3xl:mb-60">
            <Heading tag="div" text={title} titleWChild={true} classes={'title-w-em text-white'} />
          </div>
          <div className="col-span-2 xs:col-span-12 relative aspect-[9/12] md:aspect-[1315/425] rounded-[10px] overflow-hidden" ref={scope}>
            <div className="w-full h-full absolute top-0 left-0 z-20 bg-[#031218]/60"/>
            <Image
             src={image?.sourceUrl}
             sizes={image?.sizes}
             fill={true}
             className="object-cover z-10"
             alt={text}
            />
            <div className="w-full h-full absolute top-0 left-0 z-30 flex flex-col items-center justify-center gap-y-30 px-40">
              {!isEmpty(text) && (<Heading tag="h4" text={text} classes='text-white text-center leading-[110%]' animate={false} />)}
              {!isEmpty(link) && (<Btn type="cta-2-2" title={link.title} uri={link.url} target={link.target} />)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockBannerAccessories