import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import { useLayoutContext } from "../../../../../contexts";

import Image from "next/image";

import Heading from "../../../commons/Heading";
import Wysiwyg from "../../../commons/Wysiwyg";
import Btn from "../../../commons/btn";

const rightRelative = ({blockInfo}) => {
  const {configuration, content, img, index, link ,title } = blockInfo;

  const { reloadFlag } = useLayoutContext();
  const scope = useRef(null);
  const leftCol = useRef(null);
  const rightCol = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      if (reloadFlag == 'mobile') {
        gsap.from([leftCol.current, rightCol.current], {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0,
        })
      } else {
        gsap.from(leftCol.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          x: -20,
          opacity: 0,
        });
        gsap.from(rightCol.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          x: 20,
          opacity: 0,
        });
      }
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [reloadFlag])

  return (
    <div className="block-common-content relative right common-block-padding" ref={scope}>
      <div className="container grid grid-cols-mobile gap-x-mobile lg:grid-cols-normal md:gap-x-normal items-center">
        <div className="col-span-2 lg:col-span-5 xl:col-start-2 mb-40 lg:mb-0" ref={leftCol}>
          <Heading tag="div" text={title} classes="title-w-em text-white" titleWChild={true} animate={false} />
          <Wysiwyg content={content} classes={'my-20 lg:my-40  text-white'} />
          {configuration.addLink == true && (
            <Btn
              type={'cta-2-2'}
              title={link?.title}
              uri={link?.url}
              target={link?.target}
              classes={`mt-25`}
            />
          )}
        </div>
        <div className="col-span-2 lg:col-span-5 order-last lg:order-none lg:col-start-8" ref={rightCol}>
          <Image
            src={img?.sourceUrl}
            width={img?.mediaDetails?.width}
            height={img?.mediaDetails?.height}
            alt={title}
            className="w-full"
          />
        </div>
      </div>
    </div>
  )
}

export default rightRelative