import { isEmpty } from "lodash";
import { v4 } from "uuid";

import ProgrammPresentation from "./partials/ProgrammPresentation";
import Heading from "../commons/Heading";
import Btn from "../commons/btn";

const BlockProgrammPresentation = ({ blockInfo }) => {
  const {index, title, items} = blockInfo;

  if (isEmpty(items)) { return; }

  return (
    <section className={`
      block-programm-presentation block-index-${index} fb-block-padding
    `}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-mobile xs:grid-cols-normal lg:gap-x-normal lg:gap-y-0">
          <div className="col-span-2 xs:col-span-12">
            <Heading
              tag="div" text={title} titleWChild={true}
              classes={'title-w-em text-white text-center mb-40 lg:mb-60 xl:mb-80 5xl:mb-100 6xl:mb-120'}
            />
          </div>
          {items.map((item, index) => (
            <ProgrammPresentation key={v4()} item={item} index={index} length={items?.length} />
          ))}
          <div>

          </div>
          <div className="
            col-span-2 xs:col-span-12 flex flex-col md:flex-row gap-25 items-center md:justify-center
            mt-40 lg:mt-60 xl:mt-80 5xl:mt-100 6xl:mt-120
          ">
            <Btn type="normal-test" classes={'inline-flex'} />
            <Btn type="shop-outside-header" title={'Accéder à la boutique'}  />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockProgrammPresentation