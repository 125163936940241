import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import Heading from "../commons/Heading";
import ArchiveProgrammCard from "./partials/ArchiveProgrammCard";
import GlobalPreviewAudio from "../commons/GlobalPreviewAudio";

const BlockArchiveRelaxProgramms = ({ blockInfo }) => {
  const { index, suptitle, title, programms, backgroundEffects } = blockInfo;
  if (isEmpty(programms?.nodes)) { return null; }

  const { addToRefs, removeFromRefs } = useRefsContext();
  const localRef = useRef();
  const componentId = useRef(v4()).current;

  useEffect(() => {
    const currentRef = localRef.current;
    if (currentRef) {
      addToRefs(componentId, currentRef);
      return () => removeFromRefs(componentId); // Cleanup function
    }
  }, [])

  function filterTitlesAndAudios(programms) {
    return programms.map(programm => ({
      title: programm.title,
      audio: programm.acfProgramms.audio ? {
        id: programm.acfProgramms.audio.id,
        title: programm.acfProgramms.audio.title,
        description: programm.acfProgramms.audio.description,
        link: programm.acfProgramms.audio.link,
        guid: programm.acfProgramms.audio.guid,
      } : null
    }));
  }

  return (
    <section className={`
      block-archive-relax-programms block-index-${index} fb-block-padding-top lb-block-padding-bottom
      ${backgroundEffects?.options?.onEnter?.options?.background == '#FFFFFF' && 'block-bck-spa-white'}
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef} >
      <div className="container mb-20 md:mb-45 3xl:mb-60">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="col-span-2 xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3 md:col-span-12 md:col-start-1"
          >
            <Heading
              tag="div" text={suptitle}
              classes="suptitle mb-20 md:mb-0 text-center text-primary"
            />
            <Heading
              tag="div" text={title} titleWChild={true}
              classes="unexpected title text-primary text-center title-w-em"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="grid grid-cols-mobile auto-rows-[1fr] gap-mobile xs:grid-cols-normal md:gap-normal">
          {!isEmpty(programms?.nodes) && (programms?.nodes.map((programm, index) => (
            <ArchiveProgrammCard key={v4()} index={index} programm={programm} />
          )))}
          <GlobalPreviewAudio audios={filterTitlesAndAudios(programms?.nodes)} />
        </div>
      </div>
    </section>
  )
}

export default BlockArchiveRelaxProgramms