import { v4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Image from "next/image"

import { useRefsContext } from "../../../../../contexts";

const Gallery1 = ({ index, gallery, backgroundEffects }) => {
  const image = gallery[0];
  const scope = useRef(null);
  const imageRef = useRef(null);

  // background effects
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = scope.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.to(imageRef.current, {
        yPercent: -10,
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 80%',
          end: 'top top',
          scrub: 1,
        }
      })
    }, [scope]);

    return () => {
      ctx.revert();
    }
  }, [])



  return (
    <section className={`
      block-gallery type-1 block-index-${index} w-full aspect-[1512/719] relative overflow-hidden
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={scope}>
      <Image
        key={v4()}
        src={image?.sourceUrl}
        width={image?.mediaDetails?.width}
        height={image?.mediaDetails?.height}
        alt={image?.altText}
        className="w-auto h-[110%] absolute left-0 top-0"
        ref={imageRef}
      />
    </section>
  )
}

export default Gallery1