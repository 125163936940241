import { v4 } from "uuid";

import React, { useState } from "react";
import Image from "next/image";

import FormControllerLeftSide from "./FormControllerLeftSide";

const LeftCardForm = ({leftSide, showrooms, systems}) => {
  const initialFormId = leftSide.addForm[0].idForm;

  const [formId,setFormId] = useState(initialFormId);
  const handleChangeForm = (newFormId) => {
    setFormId(newFormId);
  }

  return (
    <div className="card md:grid md:grid-cols-normal md:gap-x-[20px] lg:px-20">
      {leftSide.addForm.map((item, index) => (
        <div key={v4()} id={index == 1 ? 'location' : 'showroom'}
        className={`mt-10 mb-30 flex md:col-span-6 xl:col-span-4 items-end sm:items-center justify-between gap-20
          cursor-pointer ease-in duration-300 px-[20px] py-[50px] md:py-20 card-form rounded-[10px] xl:gap-[20px] 2xl:gap-[30px]
          ${formId === item.idForm ? '' : ' opacity-50'} ${index === 1 ? ' background-pro ' : 'xl:col-start-3 '} `}
        onClick={() => handleChangeForm(item.idForm)} >
          <div className="min-w-[60px] h-[80px] mx-auto hidden sm:block md:hidden lg:block">
            <Image
              src={item?.icon?.sourceUrl}
              width={item?.icon?.mediaDetails?.width}
              height={item?.icon?.mediaDetails?.height}
              alt={item?.icon?.altText}
              className="w-[80%] mx-auto"
            />
          </div>
          <div className="content lg:flex lg:flex-col lg:w-10/12">
            <p className={`title-onglet text-white mb-12`}>{ item.title }</p>
            <p className={`content-card text-white`}>{ item.description }</p>
          </div>
          <div className="flex flex-col justify-end self-stretch">
            <div className="aspect-square flex items-center justify-center bg-transparent border w-[25px] h-[25px] rounded-full">
              {formId === item.idForm ? (
                <span className={`icon icon-check text-white text-[10px]`}></span>
              ) : (
                null
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="col-span-12  lg:mt-40 xl:mt-67">
        <FormControllerLeftSide formId={formId} showrooms={showrooms} systems={systems} />
      </div>
    </div>
  )
}

export default LeftCardForm