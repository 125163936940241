import { isEmpty } from "lodash";

import { useEffect, useRef, useState } from "react";

import ProgressBar from "./partials/ProgressBar";

const AudioComponent = ({ audio }) => {
  if (isEmpty(audio))
    return;

  const audioRef = useRef();
  const iconRef = useRef();
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState('00:00');


  const formatTime = (seconds) => {
    seconds = parseInt(seconds);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    const audio = audioRef.current;

    const onLoadedMetadata = () => setDuration(formatTime(audio.duration));
    const updateProgress = () => {
      const { currentTime, duration } = audio;
      setCurrentTime(formatTime(audio.currentTime))
      setProgress((currentTime / duration) * 100);
    };

    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    audio.addEventListener('timeupdate', updateProgress);
    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', onLoadedMetadata);
    };
  }, [])

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      iconRef.current.classList.remove('icon-play-no-circle');
      iconRef.current.classList.add('icon-pause');
    } else {
      audio.pause();
      iconRef.current.classList.add('icon-play-no-circle');
      iconRef.current.classList.remove('icon-pause');
    }
  };


  return (
    <div className="audio-component">
      <audio
        src={audio?.guid}
        preload="true"
        playsInline
        loop={true}
        ref={audioRef}
      />
      <div className="ac-wrapper flex items-center justify-between gap-x-10 px-20 py-10 border border-secondary rounded-full">
        <ProgressBar progress={progress} />
        <div className="flex items-center gap-x-10">
          <div className="time ft-15 font-light text-secondary">{currentTime} / {duration}</div>
          <div onClick={handlePlayPause}>
            <div className="icon-wrapper relative w-30 h-30 border border-secondary rounded-full">
              <span className="icon icon-play-no-circle ft-11 leading-none absolute text-secondary top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" ref={iconRef}></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AudioComponent