import AlternativeStyle from "./partials/ProductsMea/AlternativeStyle";
import ClassicStyle from "./partials/ProductsMea/ClassicStyle";

const BlockProductsMea = ({ blockInfo }) => {
  const { index, style, nPerLine, title, products, displaybtns, backgroundEffects } = blockInfo;

  if (style == 'style1') {
    return (<ClassicStyle index={index} title={title} nPerLine={nPerLine} products={products} displayBtns={displaybtns} backgroundEffects={backgroundEffects} />)
  } else if (style == 'style2') {
    return (<AlternativeStyle index={index} title={title} nPerLine={nPerLine} products={products} displayBtns={displaybtns} backgroundEffects={backgroundEffects} />)
  }

}

export default BlockProductsMea