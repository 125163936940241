import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../contexts";

import Heading from "../commons/Heading";
import SliderArmchairs from "./partials/SliderArmchairs";
import Btn from "../commons/btn";

const BlockOthersArmchairs = ({ blockInfo }) => {
  const { index, title, armchairs, backgroundEffects, addComparatorLink } = blockInfo;
  if (isEmpty(armchairs)) { return null; }

  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  return (
    <section className={`
      block-others-programms block-index-${index} fb-block-padding-top lb-block-padding-bottom overflow-hidden
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-12">
            <Heading
              tag="div" text={title} titleWChild={true}
              classes={'title-w-em text-white mb-45 lg:mb-60 2xl:mb-75'}
            />
          </div>
          <div className="col-span-2 xs:col-span-12">
            <SliderArmchairs armchairs={armchairs} />
          </div>
        </div>

        {addComparatorLink == true && (
          <div className="mt-40 lg:mt-60 text-center">
            <Btn type="cta-5" title={'Comparez les fauteuils'} uri={'/nos-produits/nos-fauteuils/comparer/'}  />
          </div>
        )}
      </div>
    </section>
  )
}

export default BlockOthersArmchairs