import { gsap } from "gsap";

import { useRef, useEffect } from "react"

import Heading from "../commons/Heading";

const BlockHpIntroduction = ({ blockInfo }) => {
  const {index, title, subtitle, text } = blockInfo;

  const scope = useRef();
  const arrow = useRef();
  const h1Ref = useRef();
  const h2Ref = useRef();
  const scrollDownRef = useRef();

  const handleClick = event => {
    let section = event.currentTarget.parentNode.parentNode.parentNode.parentNode;
    let nextSection = section.nextElementSibling;

    if (nextSection != undefined) {
      let offset = document.querySelector('header')?.clientHeight;
      let y = nextSection.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({top: y, behavior: 'smooth'});
    }
  };

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to(arrow.current, {y: 10, duration: 1, repeat: -1})


      let tl = gsap.timeline();
      tl.to(h1Ref.current, { scale: 1, duration: .2});
      tl.to(h2Ref.current, { scale: 1, duration: .2});
      tl.to(scrollDownRef.current, {scale: 1, duration: .2});
    }, scope)

    return (() => {
      ctx.revert();
    })
  }, [])

  return (
    <section className={`
      block-hp-introduction block-index-${index} h-screen flex flex-col items-center justify-center
      relative z-10 text-white text-center
    `} ref={scope} >
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="
            col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3
            md:col-span-12 md:col-start-1
          ">
            <div ref={h1Ref} className="scale-[0.98]">
              <Heading tag="h1" text={title} classes="mb-10 hp-title" animate={false} />
            </div>
            <div ref={h2Ref} className="scale-[0.98]">
              <Heading tag="h2" text={subtitle} classes="h4 mb-40 leading-[105%]" animate={false} />
            </div>
            <div className="
              flex flex-col items-center scale-[0.98] group hover:cursor-pointer
              md:absolute md:z-10 md:bottom-[10%] md:left-1/2 md:-translate-x-1/2
              lg:hover:cursor-pointer
            " ref={scrollDownRef} onClick={handleClick}>
              <span className="mb-20 ft-11 font-light tracking-[0.3em] uppercase transition-colors group-hover:text-action">{text}</span>
              <div className="inline-block" ref={arrow}>
                <span className="icon icon-arrow-down text-[33px] 4xl:text-[42px] 5xl:text-[48px] 6xl:text-[55px] transition-colors group-hover:text-action"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockHpIntroduction