import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";

import Wysiwyg from "../commons/Wysiwyg";

const BlockPostContentImgLeft = ({ blockInfo }) => {
  const { index, content, image, displayBgContent, contentWBg} = blockInfo;

  const scope = useRef();
  const leftCol = useRef();
  const rightCol = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      if (window.innerWidth < 1024) {
        gsap.from([leftCol.current, rightCol.current], {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0
        })
      } else {
        gsap.from(leftCol.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          x: -20,
          opacity: 0
        });
        gsap.from(rightCol.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          x: 20,
          opacity: 0
        });
      }
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-post-content-img-right block-index-${index} common-block-padding
    `} ref={scope}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal lg:items-center">
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-10 md:col-start-2 lg:col-span-6 lg:col-start-1" ref={leftCol}>
            <div className="img-wrapper relative overflow-hidden rounded-[10px] mb-20 md:mb-40 lg:mb-0">
              <Image
                src={image?.sourceUrl}
                width={image?.mediaDetails?.width}
                height={image?.mediaDetails?.height}
                className='w-full h-auto'
                alt={image?.altText}
              />
            </div>
          </div>
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-10 md:col-start-2 lg:col-span-6 lg:col-start-7 xl:col-span-5 xl:col-start-8" ref={rightCol}>
            <Wysiwyg content={content} classes={`text-white`} />
            {displayBgContent && (
              <div className="bg-dark-linear-gradient backdrop-blur-[6px] rounded-[20px] p-30 md:p-45 lg:p-60 4xl:p-66 5xl:p-77 6xl:p-89">
                <Wysiwyg content={contentWBg} classes={`text-white`} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPostContentImgLeft