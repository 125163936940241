import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";
import Image from "next/image";

const TeamMemberCard = ({item, index, type}) => {
  let aspectRatio = '';
  if (type == 'left') {
    if (index % 2 == 0) {
      aspectRatio = 'aspect-[532/709]';
    } else {
      aspectRatio = 'aspect-[532/595]';
    }
  } else {
    if (index % 2 == 0) {
      aspectRatio = 'aspect-[532/595]';
    } else {
      aspectRatio = 'aspect-[532/709]';
    }
  }
  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*index
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])


  return (
    <div className={`w-full relative mb-25 rounded-[10px] overflow-hidden ${aspectRatio}`} ref={scope}>
      <Image
        src={item?.featuredImage?.node?.sourceUrl}
        sizes={item?.featuredImage?.node?.sizes}
        alt={item.title}
        fill={true}
        className={'object-cover'}
      />
      <div className="
        w-full absolute bottom-0 left-0 px-25 py-30 bg-team-linear-gradient text-white
        xl:px-50 xl:py-60
      ">
        <h5>{item.title}</h5>
        <div className="ft-20 font-light italic leading-[175%]">{item.acfTeam.jobTitle}</div>
      </div>
    </div>
  )
}

export default TeamMemberCard