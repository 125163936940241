import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from 'react';

import Wysiwyg from '../commons/Wysiwyg';
import Image from 'next/image';

const BlockPostContentImgRight = ({blockInfo}) => {
  const { index, image, content, display2cols } = blockInfo;
  const scope = useRef();
  const leftCol = useRef();
  const rightCol = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      if (window.innerWidth < 1024) {
        gsap.from([leftCol.current, rightCol.current], {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          y: 20,
          opacity: 0
        })
      } else {
        gsap.from(leftCol.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          x: -20,
          opacity: 0
        });
        gsap.from(rightCol.current, {
          scrollTrigger: {
            trigger: scope.current,
            start: 'top 66%'
          },
          x: 20,
          opacity: 0
        });
      }
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <section className={`
      block-post-content-img-right block-index-${index} common-block-padding
    `} ref={scope}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal lg:items-center">
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-1 xl:col-span-6 xl:col-start-2 order-2 lg:order-1" ref={leftCol}>
            <Wysiwyg content={content} classes={`text-white ${display2cols && 'columns-2'}`} />
          </div>
          <div className="col-span-2 xs:col-span-10 xs:col-start-2 sm:col-span-8 sm:col-start-3 md:col-span-10 md:col-start-2 lg:col-span-4 lg:col-start-9 order-1 lg:order-2 mt-20 md:mt-40 lg:mt-0" ref={rightCol}>
            <div className="img-wrapper relative overflow-hidden rounded-[10px]">
              <Image
                src={image?.sourceUrl}
                width={image?.mediaDetails?.width}
                height={image?.mediaDetails?.height}
                className='w-full h-auto'
                alt={image?.altText}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockPostContentImgRight