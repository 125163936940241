import { isEmpty } from "lodash";
import Comparator from "./partials/Comparator/Comparator";

const BlockComparator = ({blockInfo}) => {
  const { index, comparatorDatas } = blockInfo;
  if (isEmpty(comparatorDatas)) { return null; }

  return (
    <section className={`
      block-compare block-index-${index} overflow-hidden fb-block-padding-top lb-block-padding-bottom
    `}>
      <div className="container overflow-scroll scrollbar-hide">
        <Comparator comparatorDatas={comparatorDatas} />
      </div>
    </section>
  )
}

export default BlockComparator