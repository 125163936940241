import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { useEffect, useRef } from "react";
import { useRefsContext } from "../../../contexts";

import Heading from "../commons/Heading";
import SliderAccessories from "./partials/SliderAccessories";

const BlockOtherAccessories = ({ blockInfo }) => {
  const { index, title, products, backgroundEffects } = blockInfo;
  if (isEmpty(products)) { return null; }

  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  return (
    <section className={`
      block-others-accessories block-index-${index} fb-block-padding-top lb-block-padding-bottom overflow-hidden
    `} data-backgroundeffects={JSON.stringify(backgroundEffects?.options)} ref={localRef}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal lg:gap-x-normal">
          <div className="col-span-2 xs:col-span-12">
            <Heading tag="h2" text={title} classes={'text-white text-center mb-45 lg:mb-60 2xl:mb-75'} />
          </div>
          <div className="col-span-2 xs:col-span-12">
            <SliderAccessories products={products} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlockOtherAccessories