import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useEffect, useRef } from "react";

import Image from "next/image";
import Btn from "../../../commons/btn";

const InfiniteCard = ({ item, index }) => {
  const {image, text, link} = item;

  const scope = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(scope.current, {
        scrollTrigger: {
          trigger: scope.current,
          start: 'top 66%'
        },
        y: 20,
        opacity: 0,
        delay: 0.1*(index%3)
      })
    }, [scope])

    return () => {
      ctx.revert();
    }
  }, [])

  return (
    <div className="
      infinite-card h-full bg-dark-linear-gradient backdrop-blur-[6px] rounded-[10px] p-25 4xl:p-50
      grid
    " ref={scope}>
      <Image
        src={image?.sourceUrl}
        width={image?.mediaDetails?.width}
        height={image?.mediaDetails?.height}
        alt={text}
        className="mx-auto h-120 w-auto"
      />
      <div className="
        mt-25 mb-15 4xl:mt-30 6xl:mb-20 5xl:mt-35 6xl:mb-25 6xl:mt-40 6xl:mb-30
        ft-20 font-light text-center text-white
      ">{text}</div>
      <div className="text-center self-end">
        <Btn type={'cta-2-2'} title={link?.title} uri={link?.url} target={link?.target} />
      </div>
    </div>
  )
}

export default InfiniteCard