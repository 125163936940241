import { v4 } from "uuid";
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css';

import TeamCard from "./TeamCard";

const TeamSlider = ({ teamMembers }) => {

  return (
    <div className="team-slider">
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{
          768: {
            slidesPerView: 3
          },
          1024: {
            slidesPerView: 3.5
          },
          1280: {
            slidesPerView: 4
          },
          1536: {
            slidesPerView: 4.8
          }
        }}
      >
        {teamMembers.map((member, index) => (
          <SwiperSlide key={v4()} className="bg-primary">
            <TeamCard index={index} member={member} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default TeamSlider