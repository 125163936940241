import { v4 } from "uuid";
import { gsap } from "gsap";

import { useRef } from "react";

import Heading from "../../../commons/Heading";
import ComparatorCol from "./ComparatorCol"

const ComparatorPart = ({index, title, products}) => {
  const partRef = useRef(null);
  const iconRef = useRef(null);
  const contentRef = useRef(null);

  const handleClick = () => {
    if (partRef.current.classList.contains('open')) {
      gsap.to(contentRef.current, {height: 0});
      partRef.current.classList.remove('open');
      iconRef.current.classList.remove('icon-minus');
      iconRef.current.classList.add('icon-plus');
    } else {
      gsap.to(contentRef.current, {height: 'auto'});
      partRef.current.classList.add('open');
      iconRef.current.classList.remove('icon-plus');
      iconRef.current.classList.add('icon-minus');
    }
  }

  return (
    <div className={`comparator-part comparator-index-${index} ${index == 0 && 'open'}`} ref={partRef}>
      <div className="cp-header flex items-center justify-between hover:cursor-pointer" onClick={handleClick}>
        <Heading tag="h3" text={title} classes={'ft-35 text-white leading-[105%]'} animate={false} />
        <div className="icon flex items-center justify-center rounded-full">
          <span className={`ft-25 text-white ${index == 0 ? 'icon-minus' : 'icon-plus'}`} ref={iconRef} />
        </div>
      </div>
      <div className="cp-content">
        <div className={`inside flex gap-x-mobile lg:gap-x-normal common-block-padding-top ${index != 0 && 'h-0'}`} ref={contentRef}>
          {products.map(product => (<ComparatorCol key={v4()} product={product} index={index} />))}
        </div>
      </div>
    </div>
  )
}

export default ComparatorPart