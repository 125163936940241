import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { useEffect, useRef } from "react";

import { useRefsContext } from "../../../../../contexts";

import Heading from "../../../commons/Heading";
import BottomBlocksBtns from "../BottomBlocksBtns";
import AlternativeStyleCard from "./AlternativeStyleCard";

const AlternativeStyle = ({ index, title, nPerLine, products, displayBtns, backgroundEffects }) => {
  if (isEmpty(products)) { return null; }

  // background effects
  const localRef = useRef();
  if (backgroundEffects.activate) {
    const { addToRefs, removeFromRefs } = useRefsContext();
    const componentId = useRef(v4()).current;

    useEffect(() => {
      const currentRef = localRef.current;
      if (currentRef) {
        addToRefs(componentId, currentRef);
        return () => removeFromRefs(componentId); // Cleanup function
      }
    }, [])
  }

  return (
    <section className={`
      block-products-mea block-index-${index} common-block-padding
    `} data-backgroundeffects={JSON.stringify(backgroundEffects.options)} ref={localRef}>
      <div className="container">
        <Heading
          tag="div" text={title} titleWChild={true}
          classes="unexpected mb-30 2xl:mb-35 4xl:mb-40 5xl:mb-50 6xl:mb-60 text-white title-w-em text-center md:text-left"
        />


        <div className="grid grid-cols-mobile gap-mobile xs:grid-cols-normal lg:gap-x-normal lg:gap-y-0">
          {products?.map((product, index) => (
            <AlternativeStyleCard key={v4()} product={product} nPerLine={nPerLine} index={index} />
          ))}
        </div>
      </div>
      {displayBtns && <BottomBlocksBtns />}
    </section>
  )
}

export default AlternativeStyle