import { isEmpty } from "lodash";

import { useRef } from "react";

import Heading from "../commons/Heading";
import BlSystemsSlider from "./partials/BlSystemsSlider";

const BlockHpBlSystems = ({ blockInfo }) => {
  const { index, title, products, link } = blockInfo;
  if (isEmpty(products)) { return; }

  const scope = useRef();

  return (
    <section className={`
      block-hp-blsystems block-index-${index} py-50 relative z-10 overflow-hidden`
    } ref={scope}>
      <div className="container">
        <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
          <div className="
            col-span-2 mb-75 text-center
            xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3
            md:col-span-12 md:col-start-1
          ">
            <Heading
              tag="h2" text={title}
              classes="suptitle text-white text-shadow-[0_0_3px_#0B212A]"
            />
          </div>
          <BlSystemsSlider slides={products} scope={scope} link={link} />
        </div>
      </div>
    </section>
  )
}

export default BlockHpBlSystems