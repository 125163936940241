import Cf7FormWrapper from "../../forms/cf7-form-wrapper";
import ContactForm from "../../forms/contactform";

const RightCardForm = ({formId}) => {
  const regex = /\[contact-form-7 id="(\d+)"\s*title=".*"]/;
  const matches = formId.match(regex);
  let id = null;
  if (matches && matches.length > 1) {
    id = parseInt(matches[1]);
  }

  return (
    <Cf7FormWrapper formId={id}>
      <ContactForm key={formId} />
    </Cf7FormWrapper>
  )
}

export default RightCardForm