import { isEmpty } from "lodash";

import { useEffect, useRef } from "react";

const SampleAudio = ({ audio }) => {
  const audioRef = useRef();
  const iconRef = useRef();

  useEffect(() => {
    const handlePlayEvent = (e) => {
      if (audioRef.current && e.detail.audioSrc !== audio?.guid) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        iconRef.current.classList.remove('icon-sound-on');
        iconRef.current.classList.add('icon-sound-off');
        iconRef.current.parentNode.classList.remove('on-play');
      }
    };

    window.addEventListener('playAudio', handlePlayEvent);

    return () => {
      window.removeEventListener('playAudio', handlePlayEvent);
    };
  }, [audio]);

  const handleClick = (event) => {
    let icon = iconRef.current;
    let audioEl = audioRef.current;

    if (icon.parentNode.classList.contains('on-play')) {
      icon.classList.remove('icon-sound-on');
      icon.classList.add('icon-sound-off');
      audioEl.pause();
      audioEl.currentTime = 0;
    } else {
      icon.classList.remove('icon-sound-off');
      icon.classList.add('icon-sound-on');
      audioEl.play().then(() => {
        window.dispatchEvent(new CustomEvent('playAudio', { detail: { audioSrc: audio?.guid } }));
      });
    }

    icon.parentNode.classList.toggle('on-play');
  };

  return (
    <div className="sample-audio" onClick={handleClick}>
      {!isEmpty(audio) && (
        <div className="flex items-center justify-center">
          <span ref={iconRef} className="icon text-white icon-sound-off hover:cursor-pointer" />
          <audio ref={audioRef} src={audio?.guid} preload="none" playsInline loop={true}></audio>
        </div>
      )}
    </div>
  );
};

export default SampleAudio;